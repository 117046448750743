<template>
  <v-layout justify-center text-xs-center>
    <v-flex xs12 sm10 md4 lg4>
      <div>
        <v-card outlined>
          <v-img :src="imagenExplore"></v-img>
          <v-card-title>
            Search Aduugs
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="porUsuario"
              outlined
              label="Search by username"
            >
            </v-text-field>
            <v-btn @click="buscarAduug" color="secondary">
              Search
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card outlined>
          <v-list-item v-for="aduug in resultadoBusqueda" :key="aduug.aid">
            <v-list-item-content>
              {{ aduug.aduugTitle }}
            </v-list-item-content>
            <v-btn @click="clonarAduug(aduug)" color="success">
              Clone
            </v-btn>
            <divider></divider>
          </v-list-item>
           <v-list-item v-for="tarea in tareas" :key="tarea.tid">
            <v-list-item-content>
              {{ tarea.tituloTarea}}
            </v-list-item-content>
            <v-btn @click="clonarAduug(aduug)" color="success">
              Clone
            </v-btn>
            <divider></divider>
          </v-list-item>
        </v-card>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { db, auth } from "@/firebase";
import { mapMutations,mapGetters } from "vuex";

export default {
  data() {
    return {
      porUsuario: "",
      resultadoBusqueda: [],
      usuarioBuscado: [],
      tareas:[],
    };
  },
  methods: {
    ...mapMutations(["mostrarError"]),

    async buscarAduug() {
      let buscado = this.porUsuario.toLowerCase();
      this.resultadoBusqueda = [];

      try {
        let docUsuario = await db
          .collection("aduugs")
          .where("owner", "==", buscado)
          .where("public", "==", true)
          .get();

        docUsuario.forEach((doc) => {
          this.resultadoBusqueda.push(doc.data());
        });
      } catch (error) {
        this.mostrarError(
          "An error occurred querying the Aduug. Try again later." 
        );
      }
    },

    async clonarAduug(aduugParaClonar) {
      var ahora = Date.now();
      let userNameParametro = this.$route.params.userName.toLowerCase();

      let aduugNuevo = {
        actualizado: ahora,
        aduugTitle: aduugParaClonar.aduugTitle,
        aid: auth.currentUser.uid + "_" + ahora,
        archivado:false,
        created:new Date(),
        cuid: [auth.currentUser.uid],
        diasTotales: aduugParaClonar.diasTotales,
        done: false,
        fechaEnvio: new Date(),
        finicio: aduugParaClonar.finicio,
        ffin: aduugParaClonar.ffin,
        fotoPerfil: this.fotoPerfil,
        maxGap: 0,
        minGap: -0.00001,
        maxGapTxt: "0d 00:00:00",
        minGapTxt: "0d 00:00:00",
        minutosPrevistosPorDia: aduugParaClonar.minutosPrevistosPorDia,
        minutosTotalesHechos: 0,
        minutosPrevistosAhora: 0,
        owner: userNameParametro,
        public: false,
        texto: "New Aduug!!",
        uid: auth.currentUser.uid,
        user: this.nombre,
        visible: true,
      };

      let batch = db.batch();

      batch.set(db.collection("aduugs").doc(aduugNuevo.aid), aduugNuevo);

      let docs = await db
        .collection("aduugs")
        .doc(aduugParaClonar.aid)
        .collection("tareas")
        .get();

        let contador = 0

      docs.forEach((doc) => {
        
        contador++

        let resultado = doc.data()

        let nuevaTarea = {
          tituloTarea: resultado.tituloTarea,
          puntosTotales: resultado.puntosTotales,
          minutosPunto: resultado.minutosPunto,
          minutosTotales:resultado.minutosTotales,
          puntosRestantes: resultado.puntosTotales,
          valueProgressLinear: 0,
          descripcion:resultado.descripcion,
          puntosHechos: 0,
          done: false,
          tid: auth.currentUser.uid + "_" + ahora + "_" + contador,
          actualizado: ahora * -1,
        };


        batch.set(db.collection("aduugs").doc(aduugNuevo.aid).collection("tareas").doc(nuevaTarea.tid), nuevaTarea);
      });
      await batch.commit();
      this.$router.push({ name: "dashboard" });
    },
  },
  computed: {
    ...mapGetters("sesion", [
      "fotoPerfil",
      "nombre",
      "subscripcionActual",
      "verToken",
    ]),

    imagenExplore() {
      return require("@/assets/explore.png");
    },
  }
};
</script>
