import { calculaMinutosPrevistosAhora } from "@/utiles/calculaMinutosPrevistosAhora.js";

function maxMinGapTrend(finicio,minutosTotalesAduug,diasTotales,minutosTotalesHechos,minutosPrevistosPorDia) {
     //let ahora = Date.now();
    
    

     let mpa = calculaMinutosPrevistosAhora(finicio,minutosTotalesAduug,diasTotales)

      let gapDias =
        (minutosTotalesHechos - mpa) /
         minutosPrevistosPorDia;

      

      // let gapMilisegundos = gapDias * 24 * 60 * 60 * 1000;

      // var gapDate = new Date();

      // gapDate = gapDate.setMilliseconds(gapMilisegundos);

      //  let deadline = gapDate - ahora;

      //  deadline = deadline - 1000;
       
       
       return gapDias

}
export { maxMinGapTrend };
