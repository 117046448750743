<template>
  <v-layout>
    <v-flex v-if="tarea" xs12 sm10 md12 lg8>
      <div class="title">
        {{ tarea.tituloTarea }}
        <v-btn icon @click="editandoTarea = true">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>
      <v-card outlined>
        <v-card-title>
          <v-progress-linear
            v-model="tarea.valueProgressLinear"
            rounded
            height="25"
            dark
            color="indigo darken-2"
          >
            <template>
              <strong>{{ Math.ceil(tarea.valueProgressLinear) }}%</strong>
            </template>
          </v-progress-linear>
        </v-card-title>
        <v-card-text>
          <div>
            <p class="font-italic mt-2">
              <b>Total points : </b>{{ tarea.puntosTotales }}
            </p>
          </div>
          <div>
            <p class="font-italic">
              <b>Minutes per point : </b>{{ tarea.minutosPunto }}
            </p>
          </div>
          <div>
            <p class="font-italic"><b>Total time : </b>{{ tiempoTotal }}</p>
          </div>
          <div>
            <p class="font-italic">
              <b>Time completed : </b>{{ tiempoCompletado }} (
              {{ porcentajeCompletado }} %)
            </p>
          </div>
        </v-card-text>
      </v-card>

      <v-card outlined>
        <v-card-text v-model="tarea.descripcion">
          <v-btn
            @click="editandoDescripcion = true"
            color="secondary"
            flat
            icon
            small
          >
            <v-icon>edit</v-icon>
          </v-btn>
          {{ tarea.descripcion }}</v-card-text
        >
      </v-card>

      <v-card outlined>
        <v-card-text v-if="!tarea.existeIntervalo">
          <v-btn
            @click="editandoIntervalo = true"
            color="secondary"
            flat
            icon
            small
          >
            <v-icon>edit</v-icon>
          </v-btn>
          Add Interval</v-card-text
        >

        <v-card-text v-if="tarea.existeIntervalo">
          <v-btn
            @click="editandoIntervalo = true"
            color="secondary"
            flat
            icon
            small
          >
            <v-icon>edit</v-icon>
          </v-btn>
          Interval starts at {{ tarea.inicioIntervalo }}</v-card-text
        >
      </v-card>

      <div v-if="!botonPro">
        <v-card justify-center align-center outlined>
          <v-card-text>
            The statistics is a pro feature
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              :to="{ name: 'perfil', params: { userName: usuario.userName } }"
              >Get Free Trial</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
      <div v-if="botonPro">
        <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
        <calendar-heatmap
          :values="chartCalendar"
          tooltip-unit="minutes"
          :endDate="hoy"
        />
      </div>

      <v-flex xs12>
        <v-layout justify-center align-center ma-3>
          <v-btn @click="duplicarTarea">Clone Task</v-btn>
        </v-layout>
      </v-flex>

      <v-flex xs12>
        <v-layout justify-center align-center ma-3>
          <v-btn @click="borrandoTarea = true" color="error">Delete Task</v-btn>
        </v-layout>
      </v-flex>
    </v-flex>

    <v-dialog v-model="borrandoTarea" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Delete Task
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text> </v-card-text>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <v-layout justify-start>
                <v-btn @click="borrandoTarea = false">Cancel</v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout justify-end>
                <v-btn @click="borrarTarea" color="error">Delete</v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editandoTarea" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Edit Task
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="validarNuevaTarea.tituloTarea"
            label="New title"
            :error-messages="erroresTituloNuevaTarea"
            @blur="$v.validarNuevaTarea.tituloTarea.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <v-layout justify-start>
                <v-btn @click="editandoTarea = false">Cancel</v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout justify-end>
                <v-btn
                  @click="editarTituloTarea"
                  color="secondary"
                  :disabled="$v.validarNuevaTarea.$invalid"
                  :depressed="$v.validarNuevaTarea.$invalid"
                  >Save</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editandoIntervalo" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Edit Interval
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="validarIntervalo.inicioIntervalo"
            label="Interval Start (optional)"
            :error-messages="erroresInicioIntervalo"
            @blur="$v.validarIntervalo.inicioIntervalo.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <v-layout justify-start>
                <v-btn @click="editandoIntervalo = false">Cancel</v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout justify-end>
                <v-btn
                  @click="editarIntervalo"
                  color="secondary"
                  :disabled="$v.validarIntervalo.inicioIntervalo.$invalid"
                  :depressed="$v.validarIntervalo.inicioIntervalo.$invalid"
                  >Save</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editandoDescripcion" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Add description
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            label="Add your Description"
            v-model="edicionDescripcion"
            counter="300"
            autofocus
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <v-layout justify-start>
                <v-btn @click="editandoDescripcion = false">Cancel</v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout justify-end>
                <v-btn @click="guardarDescripcion" color="secondary"
                  >Save</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { db, firebase } from "@/firebase";
import { mapMutations, mapGetters, mapState } from "vuex";
import { required, maxLength, integer } from "vuelidate/lib/validators";
import { calculaMinutosTotalesHechos } from "@/utiles/calculaMinutosTotalesHechos.js";
import { GChart } from "vue-google-charts";
import { CalendarHeatmap } from "vue-calendar-heatmap";

export default {
  data() {
    return {
      tarea: null,
      tiempoTotal: "",
      tiempoCompletado: "",
      porcentajeCompletado: "",
      spark: [],
      borrandoTarea: false,
      editandoTarea: false,
      editandoDescripcion: false,
      editandoIntervalo: false,
      edicionDescripcion: "",
      validarNuevaTarea: {
        tituloTarea: "",
      },
      validarIntervalo: {
        inicioIntervalo: "",
      },
      chartData: [
        [
          { label: "User", type: "string" },
          { label: "minutes", type: "number" },
        ],
      ],
      chartOptions: {
        legend: { position: "none" },
      },
      chartCalendar: [],
    };
  },

  validations: {
    validarNuevaTarea: {
      tituloTarea: {
        required,
        maxLength: maxLength(60),
      },
    },
    validarIntervalo: {
      inicioIntervalo: {
        integer,
        required,
      },
    },
  },
  components: {
    GChart,
    CalendarHeatmap,
  },

  methods: {
    ...mapMutations(["mostrarError", "mostrarOcupado", "ocultarOcupado"]),

    async consultarTarea() {
      let aid = this.$route.params.aid;
      let tid = this.$route.params.tid;
      let cuids = this.$route.params.cuid;

      try {
        let doc = await db
          .collection("aduugs")
          .doc(aid)
          .collection("tareas")
          .doc(tid)
          .get();

        this.tarea = doc.data();

        await this.calculaTiempoTotal(
          this.tarea.puntosTotales,
          this.tarea.minutosPunto
        );

        await this.calculaTiempoCompletado(
          this.tarea.puntosHechos,
          this.tarea.minutosPunto
        );

        cuids.forEach((cuid) => {
          this.consultarDatosChat(cuid);
        });

        this.calculaTantoPorcientoCompletado();
      } catch (error) {
        this.mostrarError(
          "An error occurred querying the task. Try again later."
        );
      }
    },

    async crearTarea() {
      let aid = this.$route.params.aid;

      let tid = this.$route.params.tid;

      let nuevaTarea = {
        tituloTarea: this.validarNuevaTarea.tituloTarea,
        inicioIntervalo: parseInt(this.validarNuevaTarea.inicioIntervalo),
        intervaloActual:
          parseInt(this.validarNuevaTarea.inicioIntervalo) +
          this.tarea.puntosHechos,
        existeIntervalo: true,
      };

      await db
        .collection("aduugs")
        .doc(aid)
        .collection("tareas")
        .doc(tid)
        .update(nuevaTarea);

      this.consultarTarea();

      this.editandoTarea = false;
    },

    async consultarDatosChat(cuid) {
      let aid = this.$route.params.aid;
      let tid = this.$route.params.tid;

      let docs = await db
        .collection("aduugs")
        .doc(aid)
        .collection("chat")
        .where("uid", "==", cuid)
        .where("tid", "==", tid)
        .get();

      let minutosColaborador = [];
      let nombreColaborador = "";
      let minutosTotales = "";
      let arrayCalendar = [];
      let fecha = "";
      let totalMinutos = "";

      docs.forEach((doc) => {
        minutosColaborador.push(doc.data().minutos);
        nombreColaborador = doc.data().user;

        fecha = this.formateaFecha(doc.data().fechaEnvioChart);

        arrayCalendar.push({ date: fecha, count: doc.data().minutos });

        minutosTotales = minutosColaborador.reduce(function(acc, element) {
          return acc + element;
        });
      });

      this.chartData.push([nombreColaborador, minutosTotales]);

      arrayCalendar.forEach((doc) => {
        let pos = this.chartCalendar
          .map(function(e) {
            return e.date;
          })
          .indexOf(doc.date);

        if (pos == -1) {
          this.chartCalendar.push({ date: doc.date, count: doc.count });
        } else {
          totalMinutos = this.chartCalendar[pos].count + doc.count;
          this.chartCalendar.splice(pos, 1);
          this.chartCalendar.push({ date: doc.date, count: totalMinutos });
        }
      });
    },

    async editarTituloTarea() {
      let aid = this.$route.params.aid;

      let tid = this.$route.params.tid;

      let nuevaTarea = {
        tituloTarea: this.validarNuevaTarea.tituloTarea,
      };

      await db
        .collection("aduugs")
        .doc(aid)
        .collection("tareas")
        .doc(tid)
        .update(nuevaTarea);

      this.consultarTarea();

      this.editandoTarea = false;
    },

    async editarIntervalo() {
      let aid = this.$route.params.aid;

      let tid = this.$route.params.tid;

      let nuevaTarea = {
        inicioIntervalo: parseInt(this.validarIntervalo.inicioIntervalo),
        intervaloActual:
          parseInt(this.validarIntervalo.inicioIntervalo) +
          this.tarea.puntosHechos,
        existeIntervalo: true,
      };

      await db
        .collection("aduugs")
        .doc(aid)
        .collection("tareas")
        .doc(tid)
        .update(nuevaTarea);

      this.consultarTarea();

      this.editandoIntervalo = false;
    },

    async duplicarTarea() {
      var ahora = Date.now();
      var ahoraActualizado = new Date().getTime();
      let aid = this.$route.params.aid;

      let nuevaTarea = {
        tituloTarea: this.tarea.tituloTarea,
        puntosTotales: this.tarea.puntosTotales,
        minutosPunto: this.tarea.minutosPunto,
        puntosRestantes: this.tarea.puntosTotales,
        minutosTotales: this.tarea.minutosTotales,
        valueProgressLinear: 0,
        puntosHechos: 0,
        done: false,
        tid: aid + "_" + ahora,
        actualizado: ahoraActualizado * -1,
      };
      let batcht = db.batch();

      batcht.set(
        db
          .collection("aduugs")
          .doc(aid)
          .collection("tareas")
          .doc(nuevaTarea.tid),
        nuevaTarea
      );

      await batcht.commit();

      this.$router.push({ name: "aduug", params: { aid: aid } });
    },

    async guardarDescripcion() {
      let aid = this.$route.params.aid;
      let tid = this.$route.params.tid;

      if (this.edicionDescripcion == this.tarea.descripcion) {
        this.editandoDescripcion = false;
        return;
      }

      this.mostrarOcupado({
        titulo: "Actualizando Información",
        mensaje: "Estamos guardando la descripción...",
      });

      try {
        await db
          .collection("aduugs")
          .doc(aid)
          .collection("tareas")
          .doc(tid)
          .update({ descripcion: this.edicionDescripcion });

        this.tarea.descripcion = this.edicionDescripcion;

        this.editandoDescripcion = false;
      } catch (error) {
        this.mostrarError("Ocurrió un error actualizando la discripción");
      } finally {
        this.ocultarOcupado();
      }
    },

    calculaTiempoTotal(ph, mp) {
      var minutosTotales = ph * mp;

      var dias = Math.floor(minutosTotales / 1440);
      var horas = Math.floor((minutosTotales % 1440) / 60);
      var minutos = minutosTotales % 60;

      //Anteponiendo un 0 a los dias si son menos de 10
      dias = dias < 10 ? "0" + dias : dias;

      //Anteponiendo un 0 a los horas si son menos de 10
      horas = horas < 10 ? "0" + horas : horas;

      //Anteponiendo un 0 a los segundos si son menos de 10
      minutos = minutos < 10 ? "0" + minutos : minutos;

      var result = dias + " d " + horas + " h " + minutos + " m"; // 2:41:30

      this.tiempoTotal = result;
    },

    calculaTiempoCompletado(pt, mp) {
      var minutosTotales = pt * mp;

      var dias = Math.floor(minutosTotales / 1440);
      var horas = Math.floor((minutosTotales % 1440) / 60);
      var minutos = minutosTotales % 60;

      //Anteponiendo un 0 a los dias si son menos de 10
      dias = dias < 10 ? "0" + dias : dias;

      //Anteponiendo un 0 a los horas si son menos de 10
      horas = horas < 10 ? "0" + horas : horas;

      //Anteponiendo un 0 a los segundos si son menos de 10
      minutos = minutos < 10 ? "0" + minutos : minutos;

      var result = dias + " d " + horas + " h " + minutos + " m"; // 2:41:30

      this.tiempoCompletado = result;
    },

    calcularLineaProgreso(ph, pt) {
      let nuevoValor;

      if (ph <= 0) {
        return;
      } else {
        nuevoValor = (ph / pt) * 100;
      }

      return nuevoValor;
    },

    calculaTantoPorcientoCompletado() {
      var resultado;

      resultado = Math.floor(
        (this.tarea.puntosHechos / this.tarea.puntosTotales) * 100
      );

      this.porcentajeCompletado = resultado;
    },

    formateaFecha(timestamp) {
      var date_not_formatted = new Date(timestamp);

      var formatted_string = date_not_formatted.getFullYear() + "-";

      if (date_not_formatted.getMonth() < 9) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getMonth() + 1;
      formatted_string += "-";

      if (date_not_formatted.getDate() < 10) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getDate();

      return formatted_string;
    },

    async borrarTarea() {
      let aid = this.$route.params.aid;
      let tid = this.$route.params.tid;
      try {
        await db
          .collection("aduugs")
          .doc(aid)
          .collection("tareas")
          .doc(tid)
          .delete();

        await calculaMinutosTotalesHechos(aid);
      } catch (error) {       
        var aduugRef = db.collection("aduugs").doc(aid);
        aduugRef.update({
          minutosTotalesAduug: firebase.firestore.FieldValue.delete(),
          maxGap: 0,
          minutosPrevistosPorDia: 0,
          minutosTotalesHechos:0,
          minGap: -0.00001,
          maxGapTxt: "0d 00:00:00",
          minGapTxt: "0d 00:00:00",
        });
      } finally {
        this.$router.push({ name: "aduug", params: { aid: aid } });
      }
    },
  },

  computed: {
    ...mapState("sesion", ["usuario"]),
    ...mapGetters("sesion", ["subscripcionActual"]),
    botonPro() {
      if (this.subscripcionActual == "Pro") {
        return true;
      } else {
        return false;
      }
    },
    botonGold() {
      if (this.subscripcionActual == "Gold") {
        return true;
      } else {
        return false;
      }
    },
    hoy() {
      var date_not_formatted = new Date();

      var formatted_string = date_not_formatted.getFullYear() + "-";

      if (date_not_formatted.getMonth() < 9) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getMonth() + 1;
      formatted_string += "-";

      if (date_not_formatted.getDate() < 10) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getDate();

      return formatted_string;
    },

    erroresTituloNuevaTarea() {
      let errores = [];

      if (!this.$v.validarNuevaTarea.tituloTarea.$dirty) {
        return errores;
      }

      if (!this.$v.validarNuevaTarea.tituloTarea.required) {
        errores.push("Please enter a title");
      }
      if (!this.$v.validarNuevaTarea.tituloTarea.maxLength) {
        errores.push("Maximum 60 characters");
      }
      return errores;
    },

    erroresInicioIntervalo() {
      let errores = [];

      if (!this.$v.validarIntervalo.inicioIntervalo.$dirty) {
        return errores;
      }

      if (!this.$v.validarIntervalo.inicioIntervalo.integer) {
        errores.push("Insert a integer");
      }

      return errores;
    },
  },

  created() {
    this.consultarTarea();
    this.consultarDatosChat();
  },
};
</script>
