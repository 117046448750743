<template>
  <div v-if="minutosTotalesAduug">
    <div>
      <div v-if="verFecha">
        <v-chip @click="cambiarContador" :color="colorContador" v-if="verFecha">
          <b>{{ fuerzaGap }}</b>
          <v-btn color="white" text icon small>
            <v-icon size="22">{{iconoReverse}}</v-icon>
          </v-btn>
          <b>{{ gap }}</b> </v-chip
        ><br />
      </div>
      <div v-else>
        <v-chip @click="cambiarContador" :color="colorContador">
          <v-btn color="white" text icon small>
            <v-icon size="22">event</v-icon>
          </v-btn>
          <b>{{ fechaDeadLine }}</b>
        </v-chip>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <v-chip>
        <v-btn text icon small>
          <v-icon size="22">schedule</v-icon>
        </v-btn>
        <b>{{ gap }}</b> </v-chip
      ><br />
    </div>
  </div>
</template>

<script>
import { calculaCountDown } from "@/utiles/calculaCountDown.js";
import { calculaMinutosPrevistosAhora } from "@/utiles/calculaMinutosPrevistosAhora.js";

export default {
  props: [
    "minutosTotalesHechos",
    "minutosPrevistosPorDia",
    "finicio",
    "ffin",
    "minutosTotalesAduug",
    "diasTotales",
    "minGap",
    "maxGap",
    "plan",
    "reverse",
  ],
  data() {
    return {
      gap: "",
      fechaDeadLine: "",
      verFecha: true,
      colorContador: "",
      fuerzaGap: 0,
      fuerzaGapNegativo: 0,
    };
  },
  methods: {
    iniciarContador() {
      let ahora = Date.now();

      let mpa = calculaMinutosPrevistosAhora(
        this.finicio,
        this.minutosTotalesAduug,
        this.diasTotales
      );

      let gapDias =
        (this.minutosTotalesHechos - mpa) / this.minutosPrevistosPorDia;

      if (this.plan != "Free") {
        if (gapDias > 0) {
          this.fuerzaGap = Math.floor((gapDias / this.maxGap) * 100);
        } else {
          this.fuerzaGapNegativo = Math.floor(
            ((gapDias * -1) / this.minGap) * 100
          );
          if (this.fuerzaGapNegativo < -100) {
            this.fuerzaGap = -100;
          } else {
            this.fuerzaGap = Math.floor(((gapDias * -1) / this.minGap) * 100);
          }
        }
      } else {
        this.fuerzaGap = "";
        this.fuerzaGapNegativo = "";
      }

      let gapMilisegundos = gapDias * 24 * 60 * 60 * 1000;

      var gapDate = new Date();

      gapDate = gapDate.setMilliseconds(gapMilisegundos);

      let deadline = gapDate - ahora;

      let date = new Date(gapDate);

      this.fechaDeadLine = date.toString().substring(0, 21);

      let menosDeUnDia = ahora + 86400000;

      if(this.reverse == true){
        if (gapDate <= ahora) {
        this.colorContador = "success";
      } else if (gapDate <= menosDeUnDia) {
        this.colorContador = "error";
      } else {
        this.colorContador = "error";
      }
      }else{

        
        if (gapDate <= ahora) {
          this.colorContador = "error";
      } else if (gapDate <= menosDeUnDia) {
        this.colorContador = "warning";
      } else {
        this.colorContador = "success";
      }
        }

      var aFecha1 = this.finicio.split("-");
      var aFecha2 = this.ffin.split("-");
      var fFecha1 = Date.UTC(aFecha1[0], aFecha1[1] - 1, aFecha1[2]);
      var fFecha2 = Date.UTC(aFecha2[0], aFecha2[1] - 1, aFecha2[2]);

      if (fFecha1 > ahora) {
        this.gap = "Not Started";
        this.fuerzaGap = "";
        this.colorContador = "";
        this.fechaDeadLine = this.finicio;
      } else if (!this.minutosTotalesAduug) {
        this.gap = "00d 00:00:00";
        this.fuerzaGap = "";
        this.colorContador = "";
      } else if (ahora > fFecha2 + 86400000) {
        this.gap = "Finished";
        this.fuerzaGap = "";
        this.colorContador = "";
        this.fechaDeadLine = this.ffin;
      } else {
        setInterval(() => {
          deadline = deadline - 1000;
          this.gap = calculaCountDown(deadline, ahora);
        }, 1000);
      }
    },

    cambiarContador() {
      this.verFecha = !this.verFecha;
    },
  },

  computed:{
    iconoReverse(){
      let icono = ""
      if (this.reverse == true){
         icono = "restore"
      }else{
        icono = "schedule"
      }
        return icono

    }
  },

  created() {
    this.iniciarContador();
  },
};
</script>
