import { auth, db } from "@/firebase";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    usuario: null,
    subscripcion:"Free"
  },
  mutations: {
    actualizarUsuario(state, usuario) {
      state.usuario = usuario;
    },
    actualizarNickname(state, payload) {
      if (!state.usuario) {
        return;
      }

      state.usuario.userName = payload.userName;
    },
    actualizarDescripcion(state, descripcion) {
      if (!state.usuario) {
        return;
      }

      state.usuario.descripcion = descripcion;
    },
    actualizarFotoPerfil(state, fotoPerfil) {
      if (!state.usuario) {
        return;
      }
      state.usuario.fotoPerfil = fotoPerfil;
    },
    actualizarSubscripcion(state, subscripcion) {
      if (!state.usuario) {
        return;
      }
      state.subscripcion = subscripcion;
    },
  },
  actions: {
    async iniciarSesion({ commit, getters }, uid) {
      try {
        let doc = await db
          .collection("usuarios")
          .doc(uid)
          .get();
        if (doc.exists) {
          let usuario = doc.data();
          commit("actualizarUsuario", usuario);

          switch (router.currentRoute.name) {
            case "login":
              commit("mostrarExito", getters.saludo, { route: true });
              router.push({ name: "dashboard" });
              break;

            case "accionesEmail":
              commit(
                "mostrarExito",
                getters.saludo + " your password has been changed successfully",
                { route: true }
              );
              router.push({ name: "dashboard" });
              break;
          }
        } else {
          router.push({ name: "registro" });
        }
      } catch (error) {
        commit("mostrarError", "An error occurred querying your information", {
          root: true,
        });
      }

    },
    cerrarSesion({ commit }) {
      auth.signOut();
      commit("actualizarUsuario", null);
    },

  },
  modules: {},
  getters: {
    saludo(state) {
      if (!state.usuario) {
        return "Welcome";
      }
      return `Welcome ${state.usuario.userName}`;
    },
    fotoPerfil(state) {
      if (!state.usuario || !state.usuario.fotoPerfil) {
        return require("@/assets/fotoUsuario.png");
      } else {
        return state.usuario.fotoPerfil;
      }
    },
    nombre(state) {
      if (!state.usuario) {
        return;
      }
      return `${state.usuario.userName}`;
    },
    verToken(state) {
      if (!state.usuario) {
        return;
      }
      return `${state.usuario.token}`;
    },
    subscripcionActual(state) {
      if (!state.subscripcion) {
        return;
      }
      return `${state.subscripcion}`;
    },
  },
};
