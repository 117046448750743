import { db } from "@/firebase";

async function calculaMinutosTotalesAduug(minutosTotales,aid) {


  var dias = Math.floor(minutosTotales / 1440);
  var horas = Math.floor((minutosTotales % 1440) / 60);
  var minutos = minutosTotales % 60;

  //Anteponiendo un 0 a los dias si son menos de 10
  dias = dias < 10 ? "0" + dias : dias;

  //Anteponiendo un 0 a los horas si son menos de 10
  horas = horas < 10 ? "0" + horas : horas;

  //Anteponiendo un 0 a los segundos si son menos de 10
  minutos = minutos < 10 ? "0" + minutos : minutos;

  var result = dias + " d " + horas + " h " + minutos + " m"; // 2:41:30

  let cambio = {
    minutosTotalesAduug: minutosTotales 
  };

  await db
  .collection("aduugs")
  .doc(aid)
  .update(cambio);

  return result;
}

export { calculaMinutosTotalesAduug };
