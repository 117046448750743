<template>
  <v-layout justify-center align-center>
    <div class="home-titulo">
      <v-layout justify-center align-center row wrap ma-1>
        <v-chip v-if="aduugs.length > 0" label
          >{{ cargaTiempoContador }}/Day</v-chip
        >
      </v-layout>
      <v-layout justify-center align-center>
        <v-flex row wrap ma-2>
          <v-card
            v-for="aduug in aduugs"
            :key="aduug.aid"
            class="elevation-5 ma-1 d-flex flex-wrap justify-center align-center"
            max-width="400"
            shaped
          >
            <v-card-title primary-title>
              <v-btn text :to="{ name: 'aduug', params: { aid: aduug.aid } }">
                <v-icon
                  class="animate__animated animate__infinite animate__slow animate__fadeInLeft"
                  v-if="!aduug.finicio"
                  >navigate_next</v-icon
                >
                {{ aduug.aduugTitle }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-layout row wrap justify-center align-center>
                <Contador
                  :minutosTotalesHechos="aduug.minutosTotalesHechos"
                  :minutosPrevistosPorDia="aduug.minutosPrevistosPorDia"
                  :key="contadorKey"
                  :finicio="aduug.finicio"
                  :ffin="aduug.ffin"
                  :minutosTotalesAduug="aduug.minutosTotalesAduug"
                  :diasTotales="aduug.diasTotales"
                  :minGap="aduug.minGap"
                  :maxGap="aduug.maxGap"
                  :plan="subscripcionActual"
                  :reverse="aduug.reverse"
                ></Contador>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-list-item-avatar>
                <v-img :src="aduug.fotoPerfil"></v-img>
              </v-list-item-avatar>
              <v-layout column>
                <div outlined class="chat-fecha">
                  {{ aduug.user }} {{ convertirFecha(aduug.fechaEnvio) }}
                </div>
                <router-link
                  :to="{
                    name: 'chat',
                    params: { aid: aduug.aid, aname: aduug.aduugTitle },
                  }"
                >
                  {{ convertirTexto(aduug.texto) }}
                </router-link>
              </v-layout>
            </v-card-actions>
          </v-card>
          <div v-if="imagenRocket == true">
            <v-card outlined>
              <v-img
                class="imagenDash"
                src="../assets/RocketLaunch.gif"
              ></v-img>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
      <v-layout justify-center align-center mt-3>
        <v-btn @click="creandoAduug = true" color="secondary"
          >Create new aduug</v-btn
        >
      </v-layout>
    </div>
    <v-dialog v-model="creandoAduug" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Create New Aduug
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="aduugTitle"
            label="Aduug Title"
            @keyup.enter="crearAduug"
            :error-messages="erroresNuevoAduug"
            @blur="$v.aduugTitle.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <v-layout justify-start>
                <v-btn @click="creandoAduug = false">Cancel</v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout justify-end>
                <v-btn
                  @click="crearAduug"
                  color="secondary"
                  :disabled="$v.aduugTitle.$invalid"
                  :depressed="$v.aduugTitle.$invalid"
                  >Save</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalFree" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Unlimited Aduugs & More
          </v-toolbar-title>
        </v-toolbar>
        <v-img
                src="../assets/FreeTrial.gif"
              ></v-img>
          <v-card-actions>
            <v-btn @click="modalFree = false">Maybe later</v-btn>
            <v-btn color="success" :to="{ name: 'perfil'}" @click="modalFree = false">Go! :)</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { auth, db } from "@/firebase";
import { required, maxLength } from "vuelidate/lib/validators";
import Contador from "@/components/Contador.vue";
import { maxMinGap } from "@/utiles/maxMinGap.js";

export default {
  data() {
    return {
      creandoAduug: false,
      modalFree:false,
      aduugTitle: "",
      aduugs: [],
      contadorKey: 0,
      cargaTiempo: [],
      cargaTiempoContador: "",
      imagenRocket: false,
    };
  },

  created() {
    this.snapConsultarAduugs();
    this.verSubscripcion();
    // messaging
    //   .getToken()
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       let cambio = {
    //         token: currentToken,
    //       };
    //       db.collection("usuarios")
    //         .doc(auth.currentUser.uid)
    //         .update(cambio);
    //     } else {
    //       messaging
    //         .requestPermission()
    //         .then(function() {
    //           return messaging.getToken();
    //         })
    //         .then(function(token) {
    //           let cambio = {
    //             token: token,
    //           };

    //           db.collection("usuarios")
    //             .doc(auth.currentUser.uid)
    //             .update(cambio);
    //         })
    //         .catch(function() {
    //           this.mostrarAdvertencia(
    //             "An error ocurred with push notifications, please active permission to recive."
    //           );
    //         });
    //     }
    //   })
    //   .catch(() => {
    //     this.mostrarAdvertencia(
    //             "An error ocurred with push notifications, please active permission to recive."
    //           );
    //   });

    // messaging
    //   .requestPermission()
    //   .then(function() {
    //     return messaging.getToken();
    //   })
    //   .then(function(token) {
    //     let cambio = {
    //       token: token,
    //     };

    //     db.collection("usuarios")
    //       .doc(auth.currentUser.uid)
    //       .update(cambio);
    //   })
    //   .catch(function() {
    //     this.mostrarAdvertencia(
    //       "An error ocurred with push notifications, please active permission to recive."
    //     );
    //   });

    // messaging.onMessage(function(payload) {
    //   alert("onMessage", payload);
    // });
  },

  validations: {
    aduugTitle: {
      required,
      maxLength: maxLength(40),
    },
  },

  components: {
    Contador,
  },

  methods: {
    ...mapMutations([
      "mostrarAdvertencia",
      "mostrarError",
      "mostrarInformacion",
    ]),
    ...mapMutations("sesion", ["actualizarSubscripcion"]),

    async crearAduug() {
      var ahora = Date.now();
      //let userNameParametro = this.$route.params.userName.toLowerCase()
      var ahoraActualizado = new Date().getTime();

      let aduugNuevo = {
        aduugTitle: this.aduugTitle,
        done: false,
        minutosTotalesHechos: 0,
        aid: auth.currentUser.uid + "_" + ahora,
        uid: auth.currentUser.uid,
        cuid: [auth.currentUser.uid],
        tokens: [this.verToken],
        maxGap: 0,
        minGap: -0.00001,
        maxGapTxt: "0d 00:00:00",
        minGapTxt: "0d 00:00:00",
        visible: true,
        archivado: false,
        texto: "New Aduug!!",
        fechaEnvio: new Date(),
        user: this.nombre,
        public: false,
        reverse: false,
        fotoPerfil: this.fotoPerfil,
        owner: this.nombre.toLowerCase(),
        created: new Date(),
        actualizado: ahoraActualizado * -1,
      };

      if (this.botonGold || this.botonPro) {
        let batch = db.batch();

        batch.set(db.collection("aduugs").doc(aduugNuevo.aid), aduugNuevo);

        await batch.commit();
      } else {
        if (this.aduugs.length < 2) {
          let batch = db.batch();

          batch.set(db.collection("aduugs").doc(aduugNuevo.aid), aduugNuevo);

          await batch.commit();
        } else {
          this.modalFree=true
        }
      }
      this.creandoAduug = false;
      this.imagenRocket = false;
    },

    async snapConsultarAduugs() {
      let limite = 2;

      if (!this.botonGold && !this.botonPro) {
        limite = 2;
      } else {
        limite = 100;
      }

      await db
        .collection("aduugs")
        .where("cuid", "array-contains", auth.currentUser.uid)
        .where("visible", "==", true)
        .where("archivado", "==", false)
        .limit(limite)
        .onSnapshot(
          (snapshot) => {
            this.aduugs = [];
            this.cargaTiempo = [];
            try {
              snapshot.forEach((doc) => {
                this.aduugs.push(doc.data());

                if (doc.data().minutosPrevistosPorDia) {
                  let ahora = Date.now();

                  var aFecha1 = doc.data().finicio.split("-");
                  var aFecha2 = doc.data().ffin.split("-");
                  var fFecha1 = Date.UTC(
                    aFecha1[0],
                    aFecha1[1] - 1,
                    aFecha1[2]
                  );
                  var fFecha2 = Date.UTC(
                    aFecha2[0],
                    aFecha2[1] - 1,
                    aFecha2[2]
                  );

                  if (fFecha1 > ahora) {
                    this.cargaTiempo.push(0);
                  } else if (ahora > fFecha2 + 86400000) {
                    this.cargaTiempo.push(0);
                  } else if (doc.data().reverse == true) {
                    this.cargaTiempo.push(0);
                  } else {
                    this.cargaTiempo.push(
                      doc.data().minutosPrevistosPorDia / doc.data().cuid.length
                    );
                  }
                }
                maxMinGap(
                  doc.data().aid,
                  doc.data().maxGap,
                  doc.data().minGap,
                  doc.data().minutosTotalesHechos,
                  doc.data().finicio,
                  doc.data().minutosPrevistosPorDia,
                  doc.data().minutosTotalesAduug,
                  doc.data().diasTotales
                );
              });

              this.calculaCargaTiempoTotal();

              this.aduugs.sort(function compareNumbers(a, b) {
                return a.actualizado - b.actualizado;
              });

              if (this.aduugs.length == 0) {
                this.imagenRocket = true;
              }
            } catch (error) {
              // this.mostrarInformacion(
              //   "Create one Aduug with one task to Start"
              // );
            } finally {
              // this.aduugs.forEach((doc) => {
              //   //calculaMinutosPrevistosAhora(doc.aid,doc);
              // });
              if (this.aduugs.length == 0) {
                this.imagenRocket = true;
              }
              this.renderizarContador();
            }
          },
          () => {
            this.mostrarError("An error occurred updating the Aduugs");
          }
        );
    },

    convertirTexto(textoLargo) {
      if (textoLargo.length > 25) {
        return textoLargo.substring(0, 25) + "...";
      } else {
        return textoLargo.substring(0, 25);
      }
    },

    convertirFecha(timeStamp) {
      let fechaMensaje;

      let parte1 = timeStamp
        .toDate()
        .toString()
        .substring(4, 10);

      let parte2 = timeStamp
        .toDate()
        .toString()
        .substring(16, 21);

      fechaMensaje = parte2 + " " + parte1;

      return fechaMensaje;
    },

    calculaCargaTiempoTotal() {
      var minutosTotales = this.cargaTiempo.reduce(function(acc, element) {
        return acc + element;
      });

      //var dias = Math.floor(minutosTotales / 1440);
      var horas = Math.floor((minutosTotales % 1440) / 60);
      var minutos = Math.floor(minutosTotales % 60);

      //Anteponiendo un 0 a los dias si son menos de 10
      //dias = dias < 10 ? "0" + dias : dias;

      //Anteponiendo un 0 a los horas si son menos de 10
      horas = horas < 10 ? "0" + horas : horas;

      //Anteponiendo un 0 a los segundos si son menos de 10
      minutos = minutos < 10 ? "0" + minutos : minutos;

      var result = horas + " h " + minutos + " m"; // 2:41:30

      this.cargaTiempoContador = result;
    },

    renderizarContador() {
      this.contadorKey += 1;
    },
    async verSubscripcion() {
      await db
        .collection("customers")
        .doc(auth.currentUser.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          // In this implementation we only expect one active or trialing subscription to exist.

          const doc = snapshot.docs[0];

          doc
            .data()
            .product.get()
            .then((res) => {
              this.actualizarSubscripcion(res.data().description);
            })
            .catch(this.actualizarSubscripcion("Free"));
        });
    },
  },
  watch: {
    botonPro() {
      this.snapConsultarAduugs();
    },
    botonGold() {
      this.snapConsultarAduugs();
    },
  },
  computed: {
    ...mapGetters("sesion", [
      "fotoPerfil",
      "nombre",
      "subscripcionActual",
      "verToken",
    ]),
    erroresNuevoAduug() {
      let errores = [];
      if (!this.$v.aduugTitle.$dirty) {
        return errores;
      }
      if (!this.$v.aduugTitle.required) {
        errores.push("Please enter a title");
      }
      if (!this.$v.aduugTitle.maxLength) {
        errores.push("Maximum 40 characters");
      }

      return errores;
    },

    botonPro() {
      if (this.subscripcionActual == "Pro") {
        return true;
      } else {
        return false;
      }
    },
    botonGold() {
      if (this.subscripcionActual == "Gold") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.home-titulo {
  color: #553f75;
}

.chat-fecha {
  font-size: 0.8rem;
  margin: 3px;
  color: #929292;
}

.imagenDash {
  max-height: 300px;
  max-width: 300px;
}
</style>
