import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'

import {auth} from './firebase'

import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(Vuelidate)

auth.onAuthStateChanged(user => {
  if (user){
    store.dispatch('sesion/iniciarSesion', user.uid)
    .then(()=>{
      iniciarVue()
    })
  }
  else{
    store.dispatch('sesion/cerrarSesion')
    .then(()=>{
      iniciarVue()
    })
  }
})

let vue = null

function iniciarVue(){
  if (!vue){
    vue = 
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
}

