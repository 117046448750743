<template>
  <div>
    <section
      class="headline font-weight-bold d-flex justify-center align-center justify-space-around row"
    >
      <img class="iconoLogo" :src="iconoLogo" />
    </section>
    <section class="headline font-weight-bold ">
      Aduug es un nuevo sistema que genera tiempo libre a la vez que te ayuda a
      cumplir tus objetivos
    </section>
    <v-content>
      <v-layout>
        <v-flex row wrap ma-2>
          <v-card class="mx-auto ma-1" color="#26c6da" dark max-width="400">
            <v-card-title>
              <span class="title font-weight-light"></span>
              <ContadorHome
                :deadline="200000000"
                :colorContador="1"
              ></ContadorHome>
            </v-card-title>

            <v-card-text class="headline font-weight-bold text-justify ">
              "Planificando mis estudios online con Aduug ahora siempre tengo
              libre los fines de semana"
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="../assets/Foto1.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Andrew Gilber</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto ma-1" color="#ACDDE7" dark max-width="400">
            <v-card-title>
              <span class="title font-weight-light"></span>
              <ContadorHome
                :deadline="60000000"
                :colorContador="2"
              ></ContadorHome>
            </v-card-title>

            <v-card-text class="headline font-weight-bold text-justify ">
              "Tengo aduugs para leer más y hacer deporte, pero también tengo
              aduugs invertidos que me han ayudado a fumar menos y mejorar mi
              dieta."
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="../assets/Foto3.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Mark Sahin</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto ma-1" color="#ADB9E3" dark max-width="400">
            <v-card-title>
              <span class="title font-weight-light"></span>
              <ContadorHome
                :deadline="400000000"
                :colorContador="1"
              ></ContadorHome>
            </v-card-title>

            <v-card-text class="headline font-weight-bold text-justify ">
              "He creado aduugs que mis amigos copian para leer libros, ver
              series e incluso para superar las materias del instituto"
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="../assets/Foto2.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Ada Grey</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto ma-1" color="#A379C9" dark max-width="400">
            <v-card-title>
              <span class="title font-weight-light"></span>
              <ContadorHome
                :deadline="550000000"
                :colorContador="1"
              ></ContadorHome>
            </v-card-title>

            <v-card-text class="headline font-weight-bold text-justify">
              "Las tareas domésticas las
              planificamos con un aduug, es genial saber que hoy todos podemos
              descansar"
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="../assets/Foto4.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Jessica Ginsburg</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-content>
    <v-content>

    <v-layout justify-center align-center>
      <v-flex xs12 sm10 md8 lg6>
        <v-card outlined>
          <v-img :src="imagenTime"></v-img>
          <v-card-title class="font-weight-bold">Toma el control de tu tiempo</v-card-title>
          <v-card-text class="text-justify">
            <p>
              Aduug es una nueva app de productividad y gestión de tareas con un
              sistema único que sabe en todo momento cuánto trabajo deberías
              tener hecho y de esta manera puedes saber si ya es suficiente por
              hoy.
            </p>
            <p>
              Puedes compararlo con el GPS de un coche que sabe exactamente en
              qué punto del viaje te encuentras y en qué momento llegarás a tu
              destino monitorizando la carga de trabajo y distribuyendo de
              manera eficiente todo el trabajo en el tiempo disponible. ¡¡Cada
              día tienes más tiempo libre!!
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
      <v-layout justify-center align-center>
        <v-flex>
          <section class="text-justify"></section>
          <section class="text-justify">
            <p>
              Abre una cuenta gratuita en Aduug y descubre la mejor app de
              gestión del tiempo
            </p>
            <v-btn :to="{ name: 'registro' }" color="secondary">
              REGISTRATE GRATIS
            </v-btn>
          </section>
          <p></p>
          <v-card class="mx-auto" max-width="344">
            <v-img src="../assets/PWA.jpg" height="200px"></v-img>

            <v-card-title>
              Aduug es una PWA
            </v-card-title>

            <v-card-subtitle class="text-justify">
              Una PWA (Progressive Web APP) es una nueva generación de apps que
              no tienes que instalarla y no ocupa memoria en tu teléfono,
              solamente tienes que crear un icono aceptando el mensaje o en las
              opciones del navegador web en tu teléfono móvil y funcionara todo
              en la nube.
            </v-card-subtitle>
          </v-card>
        </v-flex>
      </v-layout>
    </v-content>
  </div>
</template>

<script>
import ContadorHome from "@/components/ContadorHome.vue";
import { mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["mostrarInformacion"]),

    verIdioma(){
     let idiomasDisponibles=['en','ca','es-ES']
     let idioma = navigator.language || navigator.userLanguage ;
    if(idiomasDisponibles.includes(idioma)){
      this.$i18n.locale = idioma;
    }else{
      this.$i18n.locale = 'en'
    }
   }
  },
  components: {
    ContadorHome,
  },
  created() {
    this.mostrarInformacion(
      "We use cookies, using this site you're ok with this."
    );
  },
  computed: {
    iconoLogo() {
      return require("@/assets/logo.png");
    },
    imagenTime() {
      return require("@/assets/TimeM.png");
    },
  },
};
</script>

<style>

section {
  padding: 2rem 20%;
}
.iconoLogo {
  max-height: 250px;
}

section:nth-child(2) {
  background: #172b4d;
  color: white;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  padding: 10rem 20%;
}
</style>
