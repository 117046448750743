function calculaCountDown(deadline) {
  if (deadline > 0) {
    let remainSeconds, remainMinutes, remainHours, remainDays, remainTime;

    (remainTime = (deadline + 1000) / 1000),
      (remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2)),
      (remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2)),
      (remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2)),
      (remainDays = Math.floor(remainTime / (3600 * 24)));

    return `${remainDays}d ${remainHours}:${remainMinutes}:${remainSeconds}`;
  } else {
    let remainSeconds, remainMinutes, remainHours, remainDays, remainTime;

    (remainTime = (deadline + 1000) / 1000),
      (remainSeconds = ("0" + Math.ceil(remainTime % 60) * -1).slice(-2)),
      (remainMinutes = ("0" + Math.ceil((remainTime / 60) % 60) * -1).slice(
        -2
      )),
      (remainHours = ("0" + Math.ceil((remainTime / 3600) % 24) * -1).slice(
        -2
      )),
      (remainDays = Math.ceil(remainTime / (3600 * 24)) * -1);

    return `${remainDays}d ${remainHours}:${remainMinutes}:${remainSeconds}`;
  }
}

export { calculaCountDown };
