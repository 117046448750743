<template>
  <v-layout wrap justify-center align-start>
    <v-flex>
      <v-layout justify-center>
        <v-card max-width="250">
          <v-toolbar color="primary" dark card>
            <v-toolbar-title>
              <span v-if="vista == 1">Current Image</span>
              <span v-if="vista == 2">Edit Image</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-img
              v-if="vista == 1"
              :src="fotoPerfil"
              alt="Foto de Perfil"
            ></v-img>
            <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia"></div>
          </v-card-text>
          <v-card-text>
            <v-layout v-if="vista == 1" justify-center>
              <v-btn
                color="secondary"
                :to="{ name: 'perfil', params: { userName: usuario.userName } }"
                >Cancelar</v-btn
              >
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center align-center>
            <v-btn
              v-if="vista == 2"
              color="secondary"
              :to="{
                name: 'perfil',
                params: { userName: usuario.userName },
              }"
              >Cancelar</v-btn>
            <v-btn v-if="vista == 2" @click="guardarImagen" color="secondary">
              Guardar
            </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm10 md8 lg6 xl4 mt-3>
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Upload Image
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <file-pond
            v-if="vista == 1"
            @addfile="cargarImagen"
            instant-upload="false"
            fileValidateTypeLabelExpectedTypes="Expected {allButLastType} or {lastType}"
            labelFileTypeNotAllowed="Only jpg or png images"
            accepted-file-Types="image/jpeg, image/png"
            label-idle="Select a image"
          ></file-pond>
          <v-responsive v-if="vista == 2">
            <img ref="imagenOriginal" class="edicionImagen" />
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { db, storage } from "@/firebase";
import { mapState, mapGetters, mapMutations } from "vuex";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import Cropper from "cropperjs/dist/cropper";
import "cropperjs/dist/cropper.css";
import { v4 as uuidv4 } from "uuid";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  components: { FilePond },
  data() {
    return {
      vista: 1,
      cropper: null,
    };
  },
  computed: {
    ...mapState("sesion", ["usuario"]),
    ...mapGetters("sesion", ["fotoPerfil"]),
  },
  methods: {
    ...mapMutations(["mostrarError", "mostrarOcupado", "ocultarOcupado"]),
    ...mapMutations("sesion",["actualizarFotoPerfil"]),
    cargarImagen(error, archivo) {
      if (error) {
        this.mostrarError("An error occurred while loading the image");
        return;
      }
      this.vista = 2;

      let reader = new FileReader();

      let vm = this;

      reader.onloadend = () => {
        vm.$refs.imagenOriginal.src = reader.result;
        vm.recortarImagen();
      };

      if (archivo) {
        if (archivo.file) {
          reader.readAsDataURL(archivo.file);
        }
      }
    },
    recortarImagen() {
      this.cropper = new Cropper(this.$refs.imagenOriginal, {
        preview: this.$refs.vistaPrevia,
        aspectRatio: 1,
        modal: false,
        guides: true,
        sized: false,
        zoomable: false,
        highlight: true,
        background: false,
        autoCrop: true,
      });
    },
    async guardarImagen() {
      this.mostrarOcupado({
        titulo: "Storing Image",
        mensaje: "Updating profile picture",
      });
      let canvas = this.cropper.getCroppedCanvas();
      let imagen = canvas.toDataURL("image/jpg");
      let fotoId = uuidv4();

      try {
        let ref = storage.ref();

        let resultado = await ref
          .child(`usuarios/${this.usuario.uid}/fotos-perfil/${fotoId}.jpg`)
          .putString(imagen, "data_url");

        let url = await resultado.ref.getDownloadURL();

        await db
          .collection("usuarios")
          .doc(this.usuario.uid)
          .update({ fotoPerfil: url });

        this.actualizarFotoPerfil(url)

      } catch (error) {
        this.mostrarError(
          "An error occurred while storing the photo, please try again later"
        );
      } finally {
        this.ocultarOcupado();
        this.$router.push({ name: "perfil",params:{userName: this.usuario.userName} });
      }
    },
  },
};
</script>

<style>
.filepond--drop-label {
  font-size: 14pt !important;
}

.edicionImagen {
  width: 100%;
}

.vistaPrevia {
  width: 200px;
  height: 200px;
  overflow: hidden;
}
</style>
