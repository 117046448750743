<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Please verify your email
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="subheading">
          <v-card-title>{{this.$route.params.checkMail}}</v-card-title>
          <span>
            Look for the verification email in your inbox and click the link in that email. A confirmation message will appear in your web browser. Didn't get the email? Check your spam folder </span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="reenviarEmail" color="warning">Resend Email</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { auth } from "@/firebase";
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["mostrarExito", "mostrarError"]),
    async reenviarEmail() {
      try {
        await auth.currentUser.sendEmailVerification();
        this.mostrarExito("We have sent you a new Verification email");
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.mostrarError("An error occurred. Please try again later");
      }
    }
  }
};
</script>
