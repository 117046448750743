<template>
  <v-layout justify-center align-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-slide-y-transition mode="out-in">
        <v-card v-if="email">
          <v-toolbar color="primary" dark card>
            <v-toolbar-title>
              New Password
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="subheding">
            Add new password for {{ email }}
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="f1.password"
              label="Password"
              type="password"
              :error-messages="erroresPassword"
              @blur="$v.f1.password.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="f1.repetirPassword"
              label="Repertir Password"
              type="password"
              :error-messages="erroresRepetirPassword"
              @blur="$v.f1.repetirPassword.$touch()"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-layout justify-center>
              <v-btn
                :disabled="$v.f1.$invalid"
                @click="cambiarPassword"
                color="secondary"
                >Accept</v-btn
              >
            </v-layout>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-flex>
  </v-layout>
</template>

<script>
import { auth } from "@/firebase";
import { mapMutations, mapGetters } from "vuex";

import {
  required,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      actionCode: "",
      email: "",
      f1: {
        password: "",
        repetirPassword: ""
      }
    };
  },
  validations: {
    f1: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20)
      },
      repetirPassword: {
        sameAs: sameAs("password")
      }
    }
  },
  computed: {
    ...mapGetters("sesion", ["saludo"]),
    erroresPassword() {
      let errores = [];
      if (!this.$v.f1.password.$dirty) {
        return errores;
      }
      if (!this.$v.f1.password.required) {
        errores.push("Enter a password");
      }
      if (!this.$v.f1.password.minLength) {
        errores.push("Min 6 characters");
      }
      if (!this.$v.f1.password.maxLength) {
        errores.push("Max 20 characters");
      }

      return errores;
    },
    erroresRepetirPassword() {
      let errores = [];
      if (!this.$v.f1.repetirPassword.$dirty) {
        return errores;
      }
      if (!this.$v.f1.repetirPassword.sameAs) {
        errores.push("Passwords do not match");
      }
      return errores;
    }
  },
  methods: {
    ...mapMutations([
      "mostrarExito",
      "mostrarError",
      "mostrarOcupado",
      "ocultarOcupado",
      "mostrarInformacion"
    ]),
    ...mapMutations("sesion", ["actualizarUsuario"]),

    async validar() {
      let mode = this.$route.query.mode;
      this.actionCode = this.$route.query.oobCode;

      switch (mode) {
        case "verifyEmail":
          this.mostrarOcupado({
            titulo: "Verifying Email",
            mensaje: "Validating the Verification code"
          });

          try {
            await auth.applyActionCode(this.actionCode);
            this.mostrarExito("Email validated successfully. Enter Aduug");
            this.$router.push({ name: "login" });
          } catch (error) {
            this.mostrarError("It was not possible to validate your email address");
          } finally {
            this.ocultarOcupado();
          }

          break;

        case "resetPassword":
          this.mostrarOcupado({
            titulo: "Verifying Code",
            mensaje: "Validating code for password reset ..."
          });

          try {
            this.email = await auth.verifyPasswordResetCode(this.actionCode);
            this.mostrarInformacion(
              "Enter a new password to " + this.email
            );
          } catch (error) {
            this.mostrarError(
              "It was not possible to read the password reset code"
            );
          } finally {
            this.ocultarOcupado();
          }

          break;
      }
    },
    async cambiarPassword() {
      this.mostrarOcupado({
        titulo: "Setting new password",
        mensaje: "Changing password"
      });

      try {
        await auth.confirmPasswordReset(this.actionCode, this.f1.password);

        await auth.signInWithEmailAndPassword(this.email,this.f1.password);

      } catch (error) {
        this.mostrarError("It was not possible to change the password");
        
      } finally {
        this.ocultarOcupado();
      }
    }
  },
  created() {
    this.validar();
  }
};
</script>
