<template>
  <v-app style="background: #F6F7F9">
    <v-navigation-drawer v-model="menu" app temporary>
      <v-list subheader three-line>
        <v-layout justify-center align-center> </v-layout>
        <v-list-item v-if="!usuario" :to="{ name: 'home' }">
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>home</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="usuario"
          :to="{ name: 'perfil', params: { userName: usuario.userName } }"
        >
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>account_circle</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
          <v-chip>{{ subscripcionActual }}</v-chip>
        </v-list-item>
        <v-list-item
          v-if="usuario"
          :to="{ name: 'dashboard', params: { userName: usuario.userName } }"
        >
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>book</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="usuario"
          :to="{ name: 'explore', params: { userName: usuario.userName } }"
        >
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>explore</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Explore</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="deferredPrompt" @click="install">
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>system_update</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Install App</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!usuario" :to="{ name: 'login' }">
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>arrow_forward</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="usuario" @click="salir">
          <v-list-item-action>
            <v-list-item-icon>
              <v-icon>arrow_back</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-footer class="justify-center PWA ma-1" inset app>
        <div>
          {{ new Date().getFullYear() }} — Aduug
        </div>
      </v-footer>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="menu = !menu"></v-app-bar-nav-icon>
      <v-layout
        @click="$router.push({ name: 'dashboard' })"
        justify-center
        align-center
      >
        <img class="iconoLogo" :src="iconoLogo" />
      </v-layout>
    </v-app-bar>

    <v-content>
      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view></router-view>
        </v-slide-y-transition>
      </v-container>
    </v-content>

    <v-snackbar
      v-model="notificacion.visible"
      :color="notificacion.color"
      multi-line
      top
      timeout="5000"
      dark
    >
      {{ notificacion.mensaje }}
      <v-btn text dark @click="ocultarNotificacion">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="ocupado.visible" max-width="400" persistent>
      <v-card>
        <v-toolbar text color="primary" dark card>
          <v-toolbar-title>
            {{ ocupado.titulo }}
          </v-toolbar-title>
        </v-toolbar>
        <v-layout justify-center align-center wrap>
          <v-card-text>
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
            ></v-progress-linear>
          </v-card-text>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-footer v-if="!usuario" color="primary" dark>
      <v-row justify="center" no-gutters>
        <v-btn color="white" text rounded small :to="{ name: 'privacy' }">
          Privacy Policy
        </v-btn>

        <v-btn color="white" text rounded small :to="{ name: 'legal' }">
          Legal Advice
        </v-btn>
        <v-col class="primary text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — Aduug
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  data() {
    return {
      menu: false,
      links: ["Home", "About Us", "Team", "Services", "Blog", "Contact Us"],
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  computed: {
    ...mapState(["notificacion", "ocupado"]),
    ...mapState("sesion", ["usuario"]),
    ...mapGetters("sesion", ["subscripcionActual"]),

    iconoLogo() {
      return require("@/assets/Logo50.png");
    },
  },
  methods: {
    ...mapMutations(["ocultarNotificacion"]),
    ...mapActions("sesion", ["cerrarSesion"]),
    async install() {
      this.deferredPrompt.prompt();
    },
    salir() {
      this.cerrarSesion();
      this.menu = false;
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.iconoLogo {
  object-fit: contain;
}

.PWA {
  font-size: 0.7rem;
  margin: 3px;
  color: #929292;
}
</style>
