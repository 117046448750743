<template>
  <v-layout justify-center text-xs-center>
    <v-flex xs12 sm10 md12 lg8>
      <div>
        <h3>
          {{ aduug.aduugTitle }}
          <v-btn
            text
            icon
            :to="{ name: 'aduugconfig', params: { aid: aduug.aid } }"
            ><v-icon>settings</v-icon></v-btn
          >
        </h3>
        <Contador
          :minutosTotalesHechos="aduug.minutosTotalesHechos"
          :minutosPrevistosPorDia="aduug.minutosPrevistosPorDia"
          :key="contadorKey"
          :finicio="aduug.finicio"
          :ffin="aduug.ffin"
          :minutosTotalesAduug="aduug.minutosTotalesAduug"
          :diasTotales="aduug.diasTotales"
          :minGap="aduug.minGap"
          :maxGap="aduug.maxGap"
          :plan="subscripcionActual"
          :reverse="aduug.reverse"
        ></Contador>
      </div>
      <v-layout wrap>
        <v-flex xs12 md6>
          <v-text-field
            @click="editandoFechas = true"
            v-model="dateRangeText"
            label="Date Range"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-dialog v-model="editandoFechas" max-width="400px">
            <v-date-picker v-model="dates" range>
              <v-btn @click="editandoFechas = false" justify-start
                >Cancel</v-btn
              >
              <v-btn @click="updateDateRange" justify-end color="secondary"
                >Update</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <v-card v-if="!aduug.finicio">
            <v-img :src="imagenCalendar"></v-img>
          </v-card>
          <v-card v-if="tareas.length == 0 && aduug.finicio">
            <v-img :src="imagenTasks"></v-img>
          </v-card>
          <v-expansion-panels>
            <v-expansion-panel v-for="tarea in tareas" :key="tarea.tid">
              <v-expansion-panel-header
                :color="colorTareaHecha(tarea.puntosRestantes)"
              >
                {{ tarea.tituloTarea }}
              </v-expansion-panel-header>

              <v-expansion-panel-content
                :color="colorTareaHecha(tarea.puntosRestantes)"
              >
                <v-progress-linear
                  v-model="tarea.valueProgressLinear"
                  rounded
                  height="25"
                  dark
                  color="indigo darken-2"
                >
                  <template>
                    <strong>{{ Math.ceil(tarea.valueProgressLinear) }}%</strong>
                  </template>
                </v-progress-linear>
                <v-layout row wrap align-center justify-center ma-2>
                  <v-chip
                    v-if="tarea.existeIntervalo && tarea.intervaloActual > 0"
                    >{{ tarea.intervaloActual }}</v-chip
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      deshacerPunto(
                        tarea.tid,
                        tarea.puntosTotales,
                        tarea.puntosRestantes,
                        tarea.puntosHechos,
                        tarea.intervaloActual,
                        tarea.inicioIntervalo,
                        tarea.tituloTarea,
                        tarea.minutosPunto
                      )
                    "
                    :color="colorBotonMenos"
                    ><v-icon small>arrow_back_ios</v-icon>{{ tarea.puntosRestantes }}</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      hacerPunto(
                        tarea.tid,
                        tarea.puntosTotales,
                        tarea.puntosRestantes,
                        tarea.puntosHechos,
                        tarea.intervaloActual,
                        tarea.inicioIntervalo,
                        tarea.tituloTarea,
                        tarea.minutosPunto
                      )
                    "
                    :color="colorBotonMas"
                    >{{ tarea.puntosHechos }}
                    <v-icon small>arrow_forward_ios</v-icon></v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    :to="{
                      name: 'tarea',
                      params: { tid: tarea.tid, cuid: aduug.cuid },
                    }"
                    ><v-icon small>settings</v-icon></v-btn
                  >
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-flex xs12 md6>
            <v-layout justify-start mt-3>
              <v-btn
                v-if="aduug.finicio"
                @click="creandoTarea = true"
                color="secondary"
                >Create new task</v-btn
              >
              <v-icon class="animate__animated animate__infinite animate__slow animate__fadeInRight" v-if="tareas.length <=0 && aduug.finicio">chevron_left</v-icon>
            </v-layout>
          </v-flex>
        </v-flex>

        <v-dialog v-model="creandoTarea" max-width="400">
          <v-card>
            <v-toolbar color="primary" dark card>
              <v-toolbar-title>
                Create New Task
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="validarNuevaTarea.tituloTarea"
                label="Title"
                :error-messages="erroresTituloNuevaTarea"
                @blur="$v.validarNuevaTarea.tituloTarea.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="validarNuevaTarea.puntosTotales"
                label="Total Points"
                type="number"
                outlined
                :error-messages="erroresPuntosNuevaTarea"
                @blur="$v.validarNuevaTarea.puntosTotales.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="validarNuevaTarea.minutosPunto"
                label="Minutes per point"
                type="number"
                outlined
                :error-messages="erroresMinutosPuntoNuevaTarea"
                @blur="$v.validarNuevaTarea.minutosPunto.$touch()"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-layout>
                <v-flex xs6>
                  <v-layout justify-start>
                    <v-btn @click="creandoTarea = false">Cancel</v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout justify-end>
                    <v-btn
                      @click="crearTarea"
                      color="secondary"
                      :disabled="$v.validarNuevaTarea.$invalid"
                      :depressed="$v.validarNuevaTarea.$invalid"
                      >Save</v-btn
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { db, auth } from "@/firebase";
import { mapMutations, mapGetters } from "vuex";
import {
  required,
  maxLength,
  integer,
  minValue,
} from "vuelidate/lib/validators";
import { calculaMinutosTotalesHechos } from "@/utiles/calculaMinutosTotalesHechos.js";
import { calculaMinutosTotalesAduug } from "@/utiles/calculaMinutosTotalesAduug.js";
import { calculaMinutosPrevistosPorDia } from "@/utiles/calculaMinutosPrevistosPorDia.js";
import { maxMinGapTrend } from "@/utiles/maxMinGapTrend.js";
import Contador from "@/components/Contador.vue";

export default {
  data() {
    return {
      aduug: null,
      usuario: null,
      editandoFechas: false,
      creandoTarea: false,
      dates: [],
      tareas: [],
      puntosTareas: [],
      minutosTareas: [],
      puntosTotalesAduug: "",
      minutosTotalesAduug: "",
      dias: "0",
      tid: "",
      gap: 0,
      validarNuevaTarea: {
        tituloTarea: "",
        puntosTotales: 0,
        minutosPunto: 0,
      },
      contadorKey: 0,
    };
  },
  components: {
    Contador,
  },
  methods: {
    ...mapMutations(["mostrarError", "mostrarAdvertencia"]),

    async consultarEsteAduug() {
      let aid = this.$route.params.aid;

      let docUsuario = await db
        .collection("usuarios")
        .doc(auth.currentUser.uid)
        .get();

      this.usuario = docUsuario.data();

      try {
        let doc = await db
          .collection("aduugs")
          .doc(aid)
          .get();

        if (doc.exists) {
          this.aduug = doc.data();
          this.dates[0] = this.aduug.finicio;
          this.dates[1] = this.aduug.ffin;
        }
      } catch (error) {
        this.mostrarError(
          "An error occurred querying the Aduug. Try again later."
        );
      } finally {
        if (!this.aduug) {
          this.$router.push({ name: "404" });
        }
      }
    },

    async consultarTareas() {
      let aid = this.$route.params.aid;

      try {
        await db
          .collection("aduugs")
          .doc(aid)
          .collection("tareas")
          .orderBy("actualizado")
          .onSnapshot((snapshot) => {
            this.tareas = [];
            this.minutosTareas = [];

            snapshot.forEach((doc) => {
              this.tareas.push(doc.data());
              this.puntosTareas.push(doc.data().puntosTotales);
              this.minutosTareas.push(doc.data().minutosTotales);
            });
            this.puntosTotalesAduug = this.puntosTareas.reduce(function(
              acc,
              element
            ) {
              return acc + element;
            });

            this.minutosTotalesAduug = this.minutosTareas.reduce(function(
              acc,
              element
            ) {
              return acc + element;
            });

            calculaMinutosTotalesAduug(this.minutosTotalesAduug, aid);

            calculaMinutosPrevistosPorDia(
              this.minutosTotalesAduug,
              this.aduug.diasTotales,
              this.aduug.aid
            );
          });
      } catch (error) {
        this.mostrarAdvertencia(
          "Ocurrió un error consultando tus tareas. Crea una tarea y añade fecha de inicio y fin."
        );
      } finally {
        this.renderizarContador();
      }
    },

    async crearTarea() {
      var ahora = Date.now();
      var ahoraActualizado = new Date().getTime();

      let nuevaTarea = {
        tituloTarea: this.validarNuevaTarea.tituloTarea,
        puntosTotales: parseInt(this.validarNuevaTarea.puntosTotales),
        minutosPunto: parseInt(this.validarNuevaTarea.minutosPunto),
        minutosTotales:
          parseInt(this.validarNuevaTarea.puntosTotales) *
          parseInt(this.validarNuevaTarea.minutosPunto),
        puntosRestantes: parseInt(this.validarNuevaTarea.puntosTotales),
        valueProgressLinear: 0,
        descripcion: "Add Description",
        puntosHechos: 0,
        done: false,
        tid: this.aduug.aid + "_" + ahora,
        actualizado: ahoraActualizado * -1,
      };
      let batcht = db.batch();

      batcht.set(
        db
          .collection("aduugs")
          .doc(this.aduug.aid)
          .collection("tareas")
          .doc(nuevaTarea.tid),
        nuevaTarea
      );

      await batcht.commit();

      this.tareas.push(nuevaTarea);
      calculaMinutosPrevistosPorDia(
        this.aduug.minutosTotalesAduug,
        this.aduug.diasTotales,
        this.aduug.aid
      );
      this.consultarTareas();
      this.consultarEsteAduug();
      this.renderizarContador();

      this.creandoTarea = false;
    },

    async updateDateRange() {
      let aid = this.$route.params.aid;

      let diasT = this.restaFechas(this.dates[0], this.dates[1]);

      if (diasT <= 0) {
        this.mostrarError("End date must be after the start date.");
      } else {
        let uptadeRange = {
          finicio: this.dates[0],
          ffin: this.dates[1],
          diasTotales: diasT,
        };

        let nuevosDiasTotales = this.restaFechas(this.dates[0], this.dates[1]);

        await db
          .collection("aduugs")
          .doc(aid)
          .update(uptadeRange);

        this.consultarEsteAduug();

        this.$nextTick(() => {
          calculaMinutosPrevistosPorDia(
            this.aduug.minutosTotalesAduug,
            nuevosDiasTotales,
            this.aduug.aid
          );
          //calculaMinutosPrevistosAhora(aid, this.aduug);
        });
        this.editandoFechas = false;
        // this.$router.push({ name: "dashboard" });
      }
    },

    restaFechas(f1, f2) {
      var aFecha1 = f1.split("-");
      var aFecha2 = f2.split("-");
      var fFecha1 = Date.UTC(aFecha1[0], aFecha1[1] - 1, aFecha1[2]);
      var fFecha2 = Date.UTC(aFecha2[0], aFecha2[1] - 1, aFecha2[2]);
      var dif = fFecha2 - fFecha1;
      var dias = Math.floor(dif / (1000 * 60 * 60 * 24));

      this.dias = dias + 1;
      return dias + 1;
    },

    async hacerPunto(tid, pt, pr, ph, ia, ii, tit, mp) {
      let docaid = this.aduug.aid;
      let ahora = new Date().getTime();

      let linear = this.calcularLineaProgreso(ph + 1, pt);

      let gap = maxMinGapTrend(
        this.aduug.finicio,
        this.aduug.minutosTotalesAduug,
        this.aduug.diasTotales,
        this.aduug.minutosTotalesHechos + mp,
        this.aduug.minutosPrevistosPorDia
      );

      let cambio;
      let cambio2;

      let puntosTrue = ph + 1;

      if (puntosTrue > pt) {
        cambio = {
          done: true,
          puntosRestantes: 0,
          puntosHechos: pt,
          valueProgressLinear: linear,
          intervaloActual: ii + pt,
          actualizado: ahora * -1,
        };
      } else {
        cambio = {
          done: false,
          puntosRestantes: pr - 1,
          puntosHechos: ph + 1,
          valueProgressLinear: linear,
          intervaloActual: ia + 1,
          actualizado: ahora * -1,
        };
      }

      cambio2 = {
        actualizado: ahora * -1,
      };

      let mensajeEnviado = {
        texto: tit,
        fechaEnvio: new Date(),
        fechaEnvioChart: ahora,
        uid: auth.currentUser.uid,
        user: this.usuario.userName,
        isTarea: true,
        tid: tid,
        minutos: mp,
        gap: gap,
      };

      let ultimoMensajeEnviado = {
        texto: tit,
        fotoPerfil: this.fotoPerfil,
        fechaEnvio: new Date(),
        user: this.usuario.userName,
      };

      if (pr == 0) {
        return;
      } else {
        let batch = db.batch();

        batch.update(
          db
            .collection("aduugs")
            .doc(docaid)
            .collection("tareas")
            .doc(tid),
          cambio
        );

        batch.update(db.collection("aduugs").doc(docaid), cambio2);
        batch.update(db.collection("aduugs").doc(docaid), ultimoMensajeEnviado);

        await batch.commit();

        try {
          await db
            .collection("aduugs")
            .doc(docaid)
            .collection("chat")
            .add(mensajeEnviado);
        } catch (error) {
          this.mostrarError("Ocurrió un error. Inténtalo más tarde.");
        }

        await calculaMinutosTotalesHechos(docaid);
        await this.consultarEsteAduug();
        await this.consultarTareas();
        this.renderizarContador();
      }
    },

    async deshacerPunto(tid, pt, pr, ph, ia, ii, tit, mp) {
      let docaid = this.aduug.aid;
      let ahora = new Date().getTime();

      let linear = this.calcularLineaProgreso(ph - 1, pt);

      let gap = maxMinGapTrend(
        this.aduug.finicio,
        this.aduug.minutosTotalesAduug,
        this.aduug.diasTotales,
        this.aduug.minutosTotalesHechos - mp,
        this.aduug.minutosPrevistosPorDia
      );

      let cambio;
      let cambio2;

      if (ii) {
        if (ph - 1 <= 0) {
          cambio = {
            done: false,
            puntosRestantes: pt,
            puntosHechos: 0,
            valueProgressLinear: 0,
            intervaloActual: ii,
            actualizado: ahora * -1,
          };
        } else {
          cambio = {
            done: false,
            puntosRestantes: pr + 1,
            puntosHechos: ph - 1,
            valueProgressLinear: linear,
            intervaloActual: ia - 1,
            actualizado: ahora * -1,
          };
        }
      } else {
        if (ph - 1 <= 0) {
          cambio = {
            done: false,
            puntosRestantes: pt,
            puntosHechos: 0,
            valueProgressLinear: 0,
            actualizado: ahora * -1,
          };
        } else {
          cambio = {
            done: false,
            puntosRestantes: pr + 1,
            puntosHechos: ph - 1,
            valueProgressLinear: linear,
            actualizado: ahora * -1,
          };
        }
      }

      cambio2 = {
        actualizado: ahora * -1,
      };

      let mensajeEnviado = {
        texto: "-1 " + tit,
        fechaEnvio: new Date(),
        fechaEnvioChart: ahora,
        uid: auth.currentUser.uid,
        user: this.usuario.userName,
        isTarea: true,
        tid: tid,
        minutos: mp * -1,
        gap: gap,
      };

      let ultimoMensajeEnviado = {
        texto: "-1 " + tit,
        fotoPerfil: this.fotoPerfil,
        fechaEnvio: new Date(),
        user: this.usuario.userName,
      };

      if (ph == 0) {
        return;
      } else {
        let batch = db.batch();

        batch.update(
          db
            .collection("aduugs")
            .doc(docaid)
            .collection("tareas")
            .doc(tid),
          cambio
        );

        batch.update(db.collection("aduugs").doc(docaid), cambio2);
        batch.update(db.collection("aduugs").doc(docaid), ultimoMensajeEnviado);

        await batch.commit();

        try {
          await db
            .collection("aduugs")
            .doc(docaid)
            .collection("chat")
            .add(mensajeEnviado);
        } catch (error) {
          this.mostrarError("Ocurrió un error. Inténtalo más tarde.");
        }

        await calculaMinutosTotalesHechos(docaid);
        await this.consultarEsteAduug();
        await this.consultarTareas();
        this.renderizarContador();
      }
    },

    calcularLineaProgreso(ph, pt) {
      let nuevoValor;

      if (ph <= 0) {
        return;
      } else {
        nuevoValor = (ph / pt) * 100;
      }

      return nuevoValor;
    },

    renderizarContador() {
      this.contadorKey += 1;
    },
    colorTareaHecha(puntosRestantes) {
      let color;
      if (puntosRestantes == 0) {
        color = "#7FC782";
      } else {
        color = "white";
      }
      return color;
    },
  },

  computed: {
    ...mapGetters("sesion", ["fotoPerfil", "subscripcionActual"]),

    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    erroresTituloNuevaTarea() {
      let errores = [];

      if (!this.$v.validarNuevaTarea.tituloTarea.$dirty) {
        return errores;
      }

      if (!this.$v.validarNuevaTarea.tituloTarea.required) {
        errores.push("Please enter a title");
      }
      if (!this.$v.validarNuevaTarea.tituloTarea.maxLength) {
        errores.push("Maximum 140 characters");
      }
      return errores;
    },
    erroresPuntosNuevaTarea() {
      let errores = [];

      if (!this.$v.validarNuevaTarea.puntosTotales.$dirty) {
        return errores;
      }

      if (!this.$v.validarNuevaTarea.puntosTotales.required) {
        errores.push("Enter at least one point");
      }
      if (!this.$v.validarNuevaTarea.puntosTotales.integer) {
        errores.push("Enter a integer number");
      }
      if (!this.$v.validarNuevaTarea.puntosTotales.minValue) {
        errores.push("Minimum one point");
      }
      return errores;
    },
    erroresMinutosPuntoNuevaTarea() {
      let errores = [];

      if (!this.$v.validarNuevaTarea.minutosPunto.$dirty) {
        return errores;
      }

      if (!this.$v.validarNuevaTarea.minutosPunto.required) {
        errores.push("Enter at least 1 minute");
      }
      if (!this.$v.validarNuevaTarea.minutosPunto.integer) {
        errores.push("Enter a integer number");
      }
      if (!this.$v.validarNuevaTarea.minutosPunto.minValue) {
        errores.push("Enter at least 1 minute");
      }
      return errores;
    },

    mostrarGap() {
      if (this.tareas.length <= 0) return false;
      return true;
    },
    imagenCalendar() {
      return require("@/assets/Calendar.png");
    },
    imagenTasks() {
      return require("@/assets/tasks.png");
    },
    colorBotonMenos(){
      let color
      if(this.aduug.reverse){
        color= "success"
      }else{
        color="error"
      }
      return color
    },
    colorBotonMas(){
      let color
      if(this.aduug.reverse){
        color= "error"
      }else{
        color="success"
      }
      return color
    }
  },

  created() {
    this.consultarEsteAduug();
    this.consultarTareas();
    this.renderizarContador();
  },

  validations: {
    validarNuevaTarea: {
      tituloTarea: {
        required,
        maxLength: maxLength(140),
      },
      puntosTotales: {
        required,
        integer,
        minValue: minValue(1),
      },
      minutosPunto: {
        required,
        integer,
        minValue: minValue(1),
      },
    },
  },
};
</script>
