<template>
  <v-layout justify-center text-xs-center>
    <v-flex xs12 sm10 md12 lg8>
      <div>
        <h2>
          {{ aduug.aduugTitle }}
          <v-btn icon @click="editandoAduug = true">
            <v-icon small>edit</v-icon>
          </v-btn>
        </h2>
        <div v-if="tareas.length > 0">
          <v-layout justify-center align-center ma-3> </v-layout>
          <v-card outlined>
            <v-card-title>
              <v-progress-linear
                v-model="progresoCircular"
                rounded
                height="25"
                dark
                color="indigo darken-2"
              >
                <template>
                  <strong>{{ Math.ceil(progresoCircular) }}%</strong>
                </template>
              </v-progress-linear>
            </v-card-title>
            <v-card-text>
              <div>
                <p class="font-italic">
                  <b>Total Dedication : </b> {{ tiempoTotalAduug }}
                </p>
              </div>
              <div>
                <p class="font-italic">
                  <b>Daily Dedication : </b>
                  {{ Math.floor(aduug.minutosPrevistosPorDia) }} minutes
                </p>
              </div>
              <div>
                <p class="font-italic">
                  <b>Daily Dedication per Person : </b>
                  {{
                    Math.floor(aduug.minutosPrevistosPorDia / aduug.cuid.length)
                  }}
                  minutes
                </p>
              </div>
              <div>
                <p class="font-italic">
                  <b>Duration: </b> {{ aduug.diasTotales }} days ({{
                    diasRestantes
                  }}
                  left)
                </p>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <v-layout justify-center align-center>
            <v-alert type="info">
              Create one task to visualize data
            </v-alert>
          </v-layout>
        </div>
        <div v-if="botonGold || botonPro">
          <div v-if="!aduug.reverse">
            <v-container>
              <v-layout justify-center align-center>
                <v-chip color="success">
                  <v-icon>schedule</v-icon>
                  <b>{{ aduug.maxGapTxt }} </b>
                </v-chip>
                <v-icon @click="resetCounter = true">autorenew</v-icon>
                <v-chip color="error">
                  <v-icon>schedule</v-icon>
                  <b>{{ aduug.minGapTxt }} </b>
                </v-chip>
              </v-layout>
            </v-container>
          </div>
          <v-container>
            <div v-if="aduug.reverse">
              <v-layout justify-center align-center>
                <v-chip color="error">
                  <v-icon>restore</v-icon>
                  <b>{{ aduug.maxGapTxt }} </b>
                </v-chip>

                <v-icon @click="resetCounter = true">autorenew</v-icon>

                <v-chip color="success">
                  <v-icon>restore</v-icon>
                  <b>{{ aduug.minGapTxt }} </b>
                </v-chip>
              </v-layout>
            </div>
          </v-container>
          <div v-if="!botonPro">
            <v-card justify-center align-center outlined>
              <v-card-text>
                The statistics is a pro feature
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="success"
                  :to="{
                    name: 'perfil',
                    params: { userName: usuario.userName },
                  }"
                  >Get Free Trial</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>

          <div v-if="botonPro">
            <div class="ex1">
            <v-card outlined>
              <GChart
                type="PieChart"
                :data="chartTask"
                :options="chartOptionsTask"
              />
            </v-card>
              <v-card outlined>
                <GChart
                  :data="chartTrendLine"
                  :options="chartOptionsTrend"
                  :createChart="
                    (el, google) => new google.visualization.LineChart(el)
                  "
                  @ready="onChartReady"
                />
              </v-card>
            </div>
            <calendar-heatmap
              :values="chartCalendar"
              tooltip-unit="minutes"
              :endDate="hoy"
            />
            <v-card outlined>
              <GChart
                type="ColumnChart"
                :data="chartData"
                :options="chartOptions"
              />
            </v-card>
          </div>
        </div>
        <div v-else>
          <v-card justify-center align-center outlined>
            <v-card-text>
              Choose a Gold or Pro plan to see more information and statistics
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                :to="{ name: 'perfil', params: { userName: usuario.userName } }"
                >Get Free Trial</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
        <v-switch
          @change="permitirClonar"
          v-model="aduug.public"
          inset
          color="success"
          :label="`Allow Community Copy`"
        ></v-switch>
        <v-switch
          @change="hacerReverse"
          v-model="aduug.reverse"
          inset
          color="success"
          :label="`Reverse`"
        ></v-switch>

        <v-card outlined>
          <v-list>
            <v-list-item v-for="item in listaMiembros" :key="item.uid">
              <v-list-item-avatar>
                <v-img v-if="item.fotoPerfil" :src="item.fotoPerfil"></v-img>
                <v-img
                  v-if="!item.fotoPerfil"
                  src="@/assets/fotoUsuario.png"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.userName"> </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn v-if="item.uid == aduug.uid" color="warning" icon
                  ><v-icon>grade</v-icon></v-btn
                >
                <v-btn
                  @click="removeMember(item.uid, item.token)"
                  v-if="item.uid != aduug.uid"
                  color="error"
                  icon
                  ><v-icon>cancel</v-icon></v-btn
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn @click="nuevoMiembro = true">Add Member</v-btn>
          </v-card-actions>
        </v-card>
        <v-layout justify-center align-center ma-3>
          <v-btn @click="archivarAduug" color="warning">Pause Aduug</v-btn>
        </v-layout>
        <v-layout justify-center align-center ma-3>
          <v-btn @click="borrandoAduug = true" color="error"
            >Delete Aduug</v-btn
          >
        </v-layout>
        <v-dialog v-model="borrandoAduug" max-width="400">
          <v-card>
            <v-toolbar color="primary" dark card>
              <v-toolbar-title>
                Delete Aduug
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text> </v-card-text>
            <v-card-text>
              <v-layout>
                <v-flex xs6>
                  <v-layout justify-start>
                    <v-btn @click="borrandoAduug = false">Cancel</v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout justify-end>
                    <v-btn @click="borrarAduug" color="error">Delete</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editandoAduug" max-width="400">
          <v-card>
            <v-toolbar color="primary" dark card>
              <v-toolbar-title>
                Edit Aduug Title
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="nuevoTitulo"
                label="New Aduug Title"
                @keyup.enter="editarTituloAduug"
                :error-messages="erroresNuevoAduug"
                @blur="$v.nuevoTitulo.$touch()"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-layout>
                <v-flex xs6>
                  <v-layout justify-start>
                    <v-btn @click="editandoAduug = false">Cancel</v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout justify-end>
                    <v-btn
                      @click="editarTituloAduug"
                      color="secondary"
                      :disabled="$v.nuevoTitulo.$invalid"
                      :depressed="$v.nuevoTitulo.$invalid"
                      >Save</v-btn
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="nuevoMiembro"
          max-width="400"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar color="primary" dark card>
              <v-toolbar-title>
                Add Member
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="miembroBuscar"
                label="User Name"
              ></v-text-field>
            </v-card-text>

            <v-list>
              <v-list-item
                v-for="item in posiblesNuevosMiembros"
                :key="item.uid"
              >
                <v-list-item-action>
                  <v-btn @click="addMember(item.token)" color="success">
                    Add
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="item.userName"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-layout justify-center align-center>
              <v-card-actions>
                <v-btn @click="buscarNuevoMiembro" color="secondary"
                  >Search</v-btn
                >
              </v-card-actions>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-dialog v-model="resetCounter" max-width="400">
          <v-card>
            <v-toolbar color="primary" dark card>
              <v-toolbar-title>
                Are you sure?
              </v-toolbar-title>
            </v-toolbar>
            <v-layout>
              <v-card-text>
                Reset Max/Min Free time counters?
              </v-card-text>
            </v-layout>
            <v-card-text>
              <v-layout>
                <v-flex xs6>
                  <v-layout justify-start>
                    <v-btn @click="resetCounter = false">Cancel</v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout justify-end>
                    <v-btn
                      @click="countersReset"
                      color="secondary"
                      >Reset</v-btn
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { db, auth } from "@/firebase";
import { mapMutations, mapGetters, mapState } from "vuex";
import { GChart } from "vue-google-charts";
import { CalendarHeatmap } from "vue-calendar-heatmap";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      aduug: null,
      nuevoTitulo: "",
      tareas: [],
      cuids: [],
      listaMiembros: [],
      posiblesNuevosMiembros: [],
      cuidNuevo: "",
      tokenNuevo: "",
      idTareasBorrar: [],
      minutosPrevistosAhora: 0,
      tiempoTotalAduug: "",
      diasRestantes: 0,
      borrandoAduug: false,
      nuevoMiembro: false,
      resetCounter: false,
      miembroBuscar: "",
      editandoAduug: false,
      chartTask: [
        [
          { label: "Task", type: "string" },
          { label: "minutes", type: "number" },
        ],
      ],
      chartOptionsTask: {
        legend: { position: "center" },
      },
      chartData: [
        [
          { label: "User", type: "string" },
          { label: "minutes", type: "number" },
        ],
      ],
      chartOptions: {
        title: "Ranking",
        legend: { position: "none" },
      },
      chartOptionsTrend: {
        legend: { position: "none" },
        hAxis: { textPosition: "none" },
        title: "Free Days",
        curveType: "function",
      },
      chartCalendar: [],
      chartTrendLine: [
        [
          { label: "Date", type: "string" },
          { label: "Free Days", type: "number" },
        ],
      ],
    };
  },
  components: {
    GChart,
    CalendarHeatmap,
  },

  validations: {
    nuevoTitulo: {
      required,
      maxLength: maxLength(40),
    },
  },

  methods: {
    ...mapMutations(["mostrarError", "mostrarAdvertencia"]),

    async consultarEsteAduug() {
      let aid = this.$route.params.aid;

      try {
        let doc = await db
          .collection("aduugs")
          .doc(aid)
          .get();

        if (doc.exists) {
          this.aduug = doc.data();
          this.cuids = doc.data().cuid;
        }
      } catch (error) {
        this.mostrarError(
          "An error occurred querying the Aduug. Try again later."
        );
      } finally {
        this.calculaDiasRestantes();
        this.calculaTiempoTotalAduug();

        this.aduug.cuid.forEach((doc) => {
          this.buscaListaMiembros(doc);
        });
        this.cuids.forEach((ucuid) => {
          this.consultarDatosChat(ucuid);
        });

        if (!this.aduug) {
          this.$router.push({ name: "404" });
        }
      }
    },

    async consultarTareas() {
      let aid = this.$route.params.aid;
      try {
        let docs = await db
          .collection("aduugs")
          .doc(aid)
          .collection("tareas")
          .get();

        this.tareas = [];

        docs.forEach((doc) => {
          this.tareas.push(doc.data());
          this.chartTask.push([doc.data().tituloTarea, doc.data().minutosTotales]);
        });
      } catch (error) {
        this.mostrarAdvertencia(
          "An error occurred while querying your tasks. Create one and insert Aduug date range."
        );
      }
    },

    async consultarDatosChat(cuid) {
      let aid = this.$route.params.aid;

      let docs = await db
        .collection("aduugs")
        .doc(aid)
        .collection("chat")
        .where("uid", "==", cuid)
        .get();

      let minutosColaborador = [];
      let nombreColaborador = "";
      let minutosTotales = "";
      let arrayCalendar = [];
      let fecha = "";
      let totalMinutos = "";
      let fechaTarea = "";

      docs.forEach((doc) => {
        minutosColaborador.push(doc.data().minutos);
        nombreColaborador = doc.data().user;
        fechaTarea = new Date(doc.data().fechaEnvioChart);

        fecha = this.formateaFecha(doc.data().fechaEnvioChart);

        arrayCalendar.push({ date: fecha, count: doc.data().minutos });

        this.chartTrendLine.push([fechaTarea, doc.data().gap]);

        minutosTotales = minutosColaborador.reduce(function(acc, element) {
          return acc + element;
        });
      });

      this.chartTrendLine.sort(function compareNumbers(a, b) {
        return a[0] - b[0];
      });

      this.chartData.push([nombreColaborador, minutosTotales]);

      arrayCalendar.forEach((doc) => {
        let pos = this.chartCalendar
          .map(function(e) {
            return e.date;
          })
          .indexOf(doc.date);

        if (pos == -1) {
          this.chartCalendar.push({ date: doc.date, count: doc.count });
        } else {
          totalMinutos = this.chartCalendar[pos].count + doc.count;
          this.chartCalendar.splice(pos, 1);
          this.chartCalendar.push({ date: doc.date, count: totalMinutos });
        }
      });
    },

    async permitirClonar() {
      let aid = this.$route.params.aid;

      let cambio = { public: this.aduug.public };
      if (this.aduug.uid == auth.currentUser.uid) {
        try {
          await db
            .collection("aduugs")
            .doc(aid)
            .update(cambio);
        } catch (error) {
          this.mostrarError("An error occurred. Try again later.");
        }
      } else {
        this.mostrarAdvertencia("You do not have permissions for this action");
      }
    },

    async hacerReverse() {
      let aid = this.$route.params.aid;

      let cambio = { reverse: this.aduug.reverse };
      if (this.aduug.uid == auth.currentUser.uid) {
        try {
          await db
            .collection("aduugs")
            .doc(aid)
            .update(cambio);
        } catch (error) {
          this.mostrarError("An error occurred. Try again later.");
        }
      } else {
        this.mostrarAdvertencia("You do not have permissions for this action");
      }
    },

    async countersReset() {
      let aid = this.$route.params.aid;

      let cambio = { 
        maxGap: 0,
        minGap: -0.00001,
        maxGapTxt: "0d 00:00:00",
        minGapTxt: "0d 00:00:00",
      };
      if (this.aduug.uid == auth.currentUser.uid) {
        try {
          await db
            .collection("aduugs")
            .doc(aid)
            .update(cambio);

          this.resetCounter=false
          this.consultarEsteAduug()

        } catch (error) {
          this.mostrarError("An error occurred. Try again later.");
        }
      } else {
        this.mostrarAdvertencia("You do not have permissions for this action");
      }
    },

    async buscarNuevoMiembro() {
      try {
        let docs = await db
          .collection("usuarios")
          .where("userName", "==", this.miembroBuscar)
          .get();

        this.posiblesNuevosMiembros = [];

        docs.forEach((doc) => {
          this.posiblesNuevosMiembros.push(doc.data());
          this.cuidNuevo = doc.data().uid;
          this.tokenNuevo = doc.data().token;
        });
      } catch (error) {
        this.mostrarAdvertencia("An error occurred. Try again later.");
      }
    },

    async editarTituloAduug() {
      let aid = this.$route.params.aid;

      if (this.aduug.uid == auth.currentUser.uid) {
        let cambio = {
          aduugTitle: this.nuevoTitulo,
        };

        try {
          await db
            .collection("aduugs")
            .doc(aid)
            .update(cambio);

          this.$router.push({ name: "dashboard" });
        } catch (error) {
          this.mostrarAdvertencia(
            "An error occurred while editing the Aduug. Try again later."
          );
        }
      } else {
        this.mostrarAdvertencia(
          "You do not have permission to edit the title of the Aduug"
        );
      }
    },

    async addMember(token) {
      let aid = this.$route.params.aid;

      if (this.aduug.uid == auth.currentUser.uid) {
        this.aduug.cuid.push(this.cuidNuevo);
        this.aduug.tokens.push(token);

        let cambio = {
          cuid: this.aduug.cuid,
          tokens: this.aduug.tokens,
        };

        try {
          await db
            .collection("aduugs")
            .doc(aid)
            .update(cambio);

          this.$router.push({ name: "dashboard" });
        } catch (error) {
          this.mostrarAdvertencia(error + "Error.Please,try later.");
        }
      } else {
        this.mostrarAdvertencia("You can't add members");
      }
    },

    async removeMember(uidRemove, tokenRemove) {
      let aid = this.$route.params.aid;

      if (
        this.aduug.uid == auth.currentUser.uid ||
        auth.currentUser.uid == uidRemove
      ) {
        let index = this.aduug.cuid.indexOf(uidRemove);
        let index2 = this.aduug.tokens.indexOf(tokenRemove);

        this.aduug.cuid.splice(index, 1);
        this.aduug.tokens.splice(index2, 1);

        let cambio = {
          cuid: this.aduug.cuid,
          tokens: this.aduug.tokens,
        };

        try {
          await db
            .collection("aduugs")
            .doc(aid)
            .update(cambio);
        } catch (error) {
          this.mostrarAdvertencia(
            "An error occurred while removing the contributor. Try again later."
          );
        } finally {
          this.$router.push({ name: "dashboard" });
        }
      } else {
        this.mostrarAdvertencia(
          "You do not have permissions to delete Collaborators"
        );
      }
    },

    async buscaListaMiembros(cuid) {
      let docRef = await db
        .collection("usuarios")
        .doc(cuid)
        .get();

      this.listaMiembros.push(docRef.data());
    },

    async borrarAduug() {
      let aid = this.$route.params.aid;

      if (this.aduug.uid == auth.currentUser.uid) {
        let batch = db.batch();

        let cambio = {
          texto: "Deleted",
          fechaEnvio: new Date(),
          visible: false,
          public: false,
          archivado: false,
        };

        batch.update(db.collection("aduugs").doc(aid), cambio);

        await batch.commit();
        this.$router.push({ name: "dashboard" });
      } else {
        this.mostrarAdvertencia(
          "You do not have permissions to delete the Aduug"
        );
      }
    },

    async archivarAduug() {
      let aid = this.$route.params.aid;
      let ahora = new Date().getTime();

      if (this.botonPro == false) {
        this.mostrarAdvertencia("Pause Aduug is a Pro Feature");
      } else {
        if (this.aduug.uid == auth.currentUser.uid) {
          let batch = db.batch();

          let cambio = {
            actualizado: ahora * -1,
            archivado: true,
          };

          batch.update(db.collection("aduugs").doc(aid), cambio);

          await batch.commit();
          this.$router.push({ name: "dashboard" });
        } else {
          this.mostrarAdvertencia(
            "You do not have permissions to pause the Aduug"
          );
        }
      }
    },

    calculaDiasRestantes() {
      var anoHoy = new Date().getFullYear();
      var mesHoy = new Date().getMonth() + 1;
      var diaHoy = new Date().getDate();

      var finFecha = this.aduug.ffin.split("-");

      var fechaHoy = new Date(anoHoy, mesHoy, diaHoy);
      var fechaFin = new Date(finFecha[0], finFecha[1], finFecha[2]);
      var dif = fechaFin - fechaHoy;

      var dias = Math.floor(dif / (1000 * 60 * 60 * 24));

      if (dias > 0) {
        this.diasRestantes = dias + 1;
      } else {
        this.diasRestantes = 0;
      }
    },

    calculaTiempoTotalAduug() {
      var dias = Math.floor(this.aduug.minutosTotalesAduug / 1440);
      var horas = Math.floor((this.aduug.minutosTotalesAduug % 1440) / 60);
      var minutos = this.aduug.minutosTotalesAduug % 60;

      //Anteponiendo un 0 a los dias si son menos de 10
      dias = dias < 10 ? "0" + dias : dias;

      //Anteponiendo un 0 a los horas si son menos de 10
      horas = horas < 10 ? "0" + horas : horas;

      //Anteponiendo un 0 a los segundos si son menos de 10
      minutos = minutos < 10 ? "0" + minutos : minutos;

      var result = dias + " d " + horas + " h " + minutos + " m"; // 2:41:30

      this.tiempoTotalAduug = result;
    },

    formateaFecha(timestamp) {
      var date_not_formatted = new Date(timestamp);

      var formatted_string = date_not_formatted.getFullYear() + "-";

      if (date_not_formatted.getMonth() < 9) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getMonth() + 1;
      formatted_string += "-";

      if (date_not_formatted.getDate() < 10) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getDate();

      return formatted_string;
    },
  },

  computed: {
    ...mapState("sesion", ["usuario"]),
    ...mapGetters("sesion", ["subscripcionActual"]),
    hoy() {
      var date_not_formatted = new Date();

      var formatted_string = date_not_formatted.getFullYear() + "-";

      if (date_not_formatted.getMonth() < 9) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getMonth() + 1;
      formatted_string += "-";

      if (date_not_formatted.getDate() < 10) {
        formatted_string += "0";
      }
      formatted_string += date_not_formatted.getDate();

      return formatted_string;
    },

    progresoCircular() {
      let valor = Math.floor(
        (this.aduug.minutosTotalesHechos / this.aduug.minutosTotalesAduug) * 100
      );
      return valor;
    },
    botonPro() {
      if (this.subscripcionActual == "Pro") {
        return true;
      } else {
        return false;
      }
    },
    botonGold() {
      if (this.subscripcionActual == "Gold") {
        return true;
      } else {
        return false;
      }
    },
    erroresNuevoAduug() {
      let errores = [];
      if (!this.$v.nuevoTitulo.$dirty) {
        return errores;
      }
      if (!this.$v.nuevoTitulo.required) {
        errores.push("Please enter a title");
      }
      if (!this.$v.nuevoTitulo.maxLength) {
        errores.push("Maximum 40 characters");
      }

      return errores;
    },
  },

  created() {
    this.consultarEsteAduug();
    this.consultarTareas();
  },
};
</script>

<style scoped>
.ex1 {
  margin-bottom: 15px;
}
</style>
