<template>
  <v-layout justify-center align-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card>
        <v-toolbar color="primary" dark card>
          <v-toolbar-title>
            Login
          </v-toolbar-title>
        </v-toolbar>
        <!-- <v-card-text class="subheading text-center">
            Entra con tu cuenta de Google
          </v-card-text>
        <v-card-text>
            <v-layout justify-center>
              <a @click="ingresar('google')">
                <v-avatar tile>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="48px"
                    height="48px"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                </v-avatar>
              </a>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider> -->
          <!-- <v-card-text class="subheading text-center">
            Login con una cuenta de email y contraseña
          </v-card-text> -->
        <v-card-text>
          <v-text-field
            label="Email"
            autofocus
            v-model="formulario.email"
            :error-messages="erroresEmail"
            @blur="$v.formulario.email.$touch()"
          ></v-text-field>
          <v-text-field
            label="Password"
            type="password"
            @keyup.enter="ingresar"
            v-model="formulario.password"
            :error-messages="erroresPassword"
            @blur="$v.formulario.password.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-layout justify-center>
            <v-btn
              @click="ingresar('email')"
              :disabled="$v.formulario.$invalid"
              color="secondary"
              >Continue</v-btn
            >
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center wrap>
            <v-btn :to="{ name: 'registro' }" text color="secondary">
              Register
            </v-btn>
            <v-btn @click="SolicitudPassword = true" text color="secondary">
              Reset Password
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-dialog
        v-model="SolicitudPassword"
        persistent
        max-width="400px"
        hide-overlay
      >
        <v-card>
          <v-toolbar color="primary" dark card>
            <v-toolbar-title flat>
              Reset Password
            </v-toolbar-title>
          </v-toolbar>
            <v-subheader>
              Your Email
            </v-subheader>
            <v-card-text>
              <v-text-field
                label="Email"
                autofocus
                v-model="emailEnvio"
                :error-messages="erroresEmailEnvio"
                @blur="$v.emailEnvio.$touch()"
              ></v-text-field>
            </v-card-text>
          <v-card-text>
            <v-layout>
              <v-flex xs6>
                <v-layout justify-start>
                  <v-btn @click="SolicitudPassword = false">Cancel</v-btn>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout justify-end>
                  <v-btn
                    color="secondary"
                    @click="enviarSolicitudPassword"
                    :disabled="$v.emailEnvio.$invalid"
                    >Send</v-btn
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

import { firebase, auth } from "@/firebase";

import { mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      formulario: {
        email: "",
        password: ""
      },
      SolicitudPassword: false,
      emailEnvio: null
    }
  },
  validations: {
    formulario: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20)
      }
    },
    emailEnvio: {
      required,
      email
    }
  },
  methods: {
    ...mapMutations([
      "mostrarOcupado",
      "ocultarOcupado",
      "mostrarExito",
      "mostrarAdvertencia",
      "mostrarError"
    ]),
    ...mapMutations("sesion", ["actualizarUsuario"]),

    ingresar(metodo){
      switch(metodo){
        case 'email':
          this.ingresarEmail()
          break

        case 'google':
          this.ingresarGoogle()
          break
        
      }
    },
    async ingresarEmail() {
      if (this.$v.formulario.$invalid) {
        this.$v.formulario.$touch();
        return;
      }

      let ocupado = {
        titulo: "Checking data"
      };

      this.mostrarOcupado(ocupado);

      try {
          await auth.signInWithEmailAndPassword(
          this.formulario.email,
          this.formulario.password
        );

      } catch (error) {
        
        switch (error.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            this.mostrarAdvertencia(
              "Wrong user or password"
            );
            break;

          default:
            this.mostrarError("Error, try again later");

            break;
        }
      }
      finally{
        this.ocultarOcupado()
        this.$router.push({name:'dashboard'})
        
      }
    },

    async ingresarGoogle(){
      let provider = new firebase.auth.GoogleAuthProvider()

      provider.setCustomParameters({
        'display': 'popup'
      })

      auth.languageCode = 'en_US'

      try {
        await auth.signInWithPopup(provider)

        this.mostrarExito(this.saludo)
        this.$router.push({name:'dashboard'})

      } catch (error) {
        this.mostrarError('Error, try again later')
      }
    },

    async enviarSolicitudPassword() {
      this.SolicitudPassword = false;
      this.mostrarOcupado({
        titulo: "Reset password",
        mensaje: "Sending email"
      });

      try {
        await auth.sendPasswordResetEmail(this.emailEnvio);
        this.mostrarExito("Check you email inboox");
      } catch (error) {
        this.mostrarError("Error, try again later");
      } finally {
        this.ocultarOcupado();
      }
    }
  },
  computed: {
    ...mapGetters("sesion", ["saludo"]),
    erroresEmail() {
      let errores = [];
      if (!this.$v.formulario.email.$dirty) {
        return errores;
      }
      if (!this.$v.formulario.email.required) {
        errores.push("Email Required");
      }
      if (!this.$v.formulario.email.email) {
        errores.push("Enter a valid email");
      }
      return errores;
    },
    erroresPassword() {
      let errores = [];
      if (!this.$v.formulario.password.$dirty) {
        return errores;
      }
      if (!this.$v.formulario.password.required) {
        errores.push("Enter a password");
      }
      if (!this.$v.formulario.password.minLength) {
        errores.push("Min 6 characters");
      }
      if (!this.$v.formulario.password.maxLength) {
        errores.push("Max 20 characters");
      }

      return errores;
    },
    erroresEmailEnvio() {
      let errores = [];
      if (!this.$v.emailEnvio.$dirty) {
        return errores;
      }
      if (!this.$v.emailEnvio.required) {
        errores.push("Enter a email");
      }
      if (!this.$v.emailEnvio.email) {
        errores.push("Enter a valid email");
      }
      return errores;
    }
  }
};
</script>
