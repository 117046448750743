<template>
  <v-layout>
    <v-flex xs12 sm8 lg5>
      <v-container justify-center fill-height>
        <v-layout align-end>
          <v-flex>
            <v-card outlined color="af7faff">
              <v-card-title>
                <span>{{ aduugTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-container ref="chatContainer">
                <v-flex
                  xs7
                  :offset-xs5="item.uid == mirarUsuario"
                  my-3
                  v-for="item in chat"
                  :key="item.mid"
                >
                  <v-layout column>
                    <div outlined class="chat-fecha">
                      {{item.user}} {{ convertirFecha(item.fechaEnvio) }}
                    </div>
                    <v-card
                      :color="item.uid != mirarUsuario ? 'white' : '#dcf8c6'"
                      class="chat-mensaje"
                    >
                      <v-card-text>
                        <div></div>
                        <div>{{ item.texto }}</div>
                      </v-card-text>
                    </v-card>
                  </v-layout>
                </v-flex>
              </v-container>
              <v-card-text>
                <v-layout align-center row>
                  <v-text-field
                    readonly
                    id="scrollChat"
                    v-model="mensaje"
                    :loading="enviandoMensaje"
                    :disabled="enviandoMensaje"
                    label=""
                    ref="txtMensaje"
                    hide-details
                  >
                  </v-text-field>
                  <!-- <v-btn fab small color="primary" dark @click="enviarMensaje"
                    ><v-icon>send</v-icon></v-btn
                  > -->
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { db, auth} from "@/firebase";
import { mapMutations,mapGetters } from "vuex";

export default {
  data() {
    return {
      chat: [],
      mensaje: "",
      enviandoMensaje: false,
      cid: null,
      aduugTitle: "",
      usuario: null
    };
  },
  created() {
    this.consultarChat();
  },
  methods: {
    ...mapMutations(["mostrarAdvertencia", "mostrarError"]),

    convertirFecha(timeStamp) {
      let fechaMensaje
      
      let parte1= timeStamp
        .toDate()
        .toString()
        .substring(4, 10)

        let parte2= timeStamp
        .toDate()
        .toString()
        .substring(16, 21)

        fechaMensaje= parte2 +" "+ parte1

        return fechaMensaje
    },

    async enviarMensaje() {
      if (!this.mensaje || this.enviandoMensaje) {
        return;
      }

      this.enviandoMensaje = true;

      let aid = this.$route.params.aid;
      let ahora = new Date().getTime();

      let mensajeEnviado = {
        texto: this.mensaje,
        fechaEnvio: new Date(),
        uid: auth.currentUser.uid,
        user: this.usuario.userName,
        
      };

      let ultimoMensajeEnviado = {
        texto: this.mensaje,
        fechaEnvio: new Date(),
        user: this.usuario.userName,
        fotoPerfil: this.fotoPerfil,
        actualizado: ahora * -1

      };

      try {
        await db
          .collection("aduugs")
          .doc(aid)
          .collection("chat")
          .add(mensajeEnviado);

          await db
          .collection("aduugs")
          .doc(aid)
          .update(ultimoMensajeEnviado);

        this.mensaje = "";
      } catch (error) {
        this.mostrarError(
          "An error occurred sending the message. Try again later."
        );
      } finally {
        this.enviandoMensaje = false;
      }
    },

    async consultarChat() {
      this.chat = [];

      let aid = this.$route.params.aid;
      this.aduugTitle = this.$route.params.aname;

      let docUsuario = await db.collection("usuarios").doc(auth.currentUser.uid).get()

      this.usuario = docUsuario.data()

      await db.collection("aduugs")
        .doc(aid)
        .collection("chat")
        .orderBy("fechaEnvio")
        .onSnapshot(
          (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type == "added") {
                let mensaje = change.doc.data();
                this.chat.push(mensaje);
              }

              this.$nextTick(() => {
                if (this.$refs.chatContainer) {
                  this.$refs.chatContainer.scrollTop = 100000;
                  setTimeout(
                    document.getElementById("scrollChat").focus()
                  ,5000)
                }
              });
            });
          },
          () => {
            this.mostrarError("An error occurred retrieving messages");
          }
        );
    },
  },
  computed: {
    ...mapGetters("sesion", ["fotoPerfil"]),
    mirarUsuario() {
      return auth.currentUser.uid;
    },
  },
};
</script>

<style>
.chat-mensaje {
  border-radius: 10px;
}

.chat-fecha {
  font-size: 0.8rem;
  margin: 3px;
  color: #929292;
}
</style>
