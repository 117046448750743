import firebase from 'firebase/app'

require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
require("firebase/functions");
//require("firebase/messaging");



var firebaseConfig = {
    apiKey: "AIzaSyCqLUJlOzXr-6pzB8NLs88_w9Tog3yPqWI",
    authDomain: "aduug-1618.firebaseapp.com",
    databaseURL: "https://aduug-1618.firebaseio.com",
    projectId: "aduug-1618",
    storageBucket: "aduug-1618.appspot.com",
    messagingSenderId: "516261007383",
    appId: "1:516261007383:web:d61b96f69406bfee1b4c52",
    measurementId: "G-90NMTREZ5C"
  };

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()
//const messaging = firebase.messaging()

export {
    firebase,
    auth,
    db,
    storage,
    functions,
    //messaging
}