import moment from 'moment';

function calculaNuevaFechaDesArchivar(finicio,ffin,archivado){

    let milisegundosOK = moment()-(archivado*-1)

    let fechaInicioAntigua =moment(finicio) + milisegundosOK
    let fechaFinAntigua = moment(ffin) + milisegundosOK

      var anoInicio = new Date(fechaInicioAntigua).getFullYear();
      var mesInicio = new Date(fechaInicioAntigua).getMonth() + 1;
      var diaInicio = new Date(fechaInicioAntigua).getDate();

      var anoFin = new Date(fechaFinAntigua).getFullYear();
      var mesFin = new Date(fechaFinAntigua).getMonth() + 1;
      var diaFin = new Date(fechaFinAntigua).getDate();

   

    let cambio ={
        finicio: `${anoInicio}-${mesInicio}-${diaInicio}`,
        ffin : `${anoFin}-${mesFin}-${diaFin}`
    }
    
     

    return cambio

}

export { calculaNuevaFechaDesArchivar };