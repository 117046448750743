import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/firebase";

import Dasboard from "../views/Dashboard.vue";
import Home from "../views/Home.vue";
import Home2 from "../views/Home2.vue";
import NotFound from "../views/NotFound.vue";
import Chat from "../views/Chat.vue";
import Explore from "../views/Explore.vue";
import Help from "../views/Help.vue";
import Cookies from "../views/Cookies.vue";
import Privacy from "../views/Privacy.vue";
import Legal from "../views/Legal.vue";

import Aduug from "../views/Aduugs/Aduug.vue";
import Tarea from "../views/Aduugs/Tarea.vue";
import AduugConfig from "../views/Aduugs/AduugConfig.vue";

import Login from "../views/usuario/Login.vue";
import Perfil from "../views/usuario/Perfil.vue";
import Registro from "../views/usuario/Registro.vue";
import EnvioVerificacionEmail from "../views/usuario/envioVerificacionEmail.vue";
import AccionesEmail from "../views/usuario/AccionesEmail.vue";
import EdicionFotoPerfil from "../views/usuario/EdicionFotoPerfil.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (auth.currentUser) next({ name: 'dashboard' })
      else next()
    }
  },
  {
    path: "/home2",
    name: "home2",
    component: Home2,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dasboard,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/explore",
    name: "explore",
    component: Explore,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/help",
    name: "help",
    component: Help,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "registro",
    component: Registro
  },
  {
    path: "/emailVerification",
    name: "emailVerification",
    component: EnvioVerificacionEmail
  },
  {
    path: "/mail-actions",
    name: "accionesEmail",
    component: AccionesEmail
  },
  {
    path: "/change-photo",
    name: "edicionFotoPerfil",
    component: EdicionFotoPerfil,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/user/:userName",
    name: "perfil",
    component: Perfil,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/aduug/:aid",
    name: "aduug",
    component: Aduug,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/aduugconfig/:aid",
    name: "aduugconfig",
    component: AduugConfig,
    meta: {
      autenticado: true
    }
  },
   {
     path: "/:aid/:tid",
     name: "tarea",
     component: Tarea,
     meta: {
       autenticado: true
     }
  },
  {
    path: "/chat/:aid",
    name: "chat",
    component: Chat,
    meta: {
      autenticado: true
    }
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/legal",
    name: "legal",
    component: Legal,
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  {
    path: "*",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let user = auth.currentUser;

  if (to.matched.some(record => record.meta.autenticado)) {
    if (user) {
      if (
        user.providerData[0].providerId == "password" &&
        !user.emailVerified
      ) {
        next({ name: "emailVerification" });
      } else {
        next();
      }
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }

});

export default router;
