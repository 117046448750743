<template>  
      <div >
        <v-chip :color="contadorColor" >
          <v-btn color="white" text icon small>
            <v-icon>schedule</v-icon>
          </v-btn>
          <b>{{ gap }}</b> </v-chip
        ><br />
      </div>
</template>

<script>
import { calculaCountDown } from "@/utiles/calculaCountDown.js";


export default {
  props: [
    "deadline",
    "colorContador"
  
  ],
  data() {
    return {
      gap: "",
    };
  },
  methods: {
    iniciarContador() {
      let ahora = Date.now();

      let deadline = this.deadline;
     
          setInterval(() => {
            deadline = deadline - 1000;
            this.gap = calculaCountDown(deadline, ahora);
          }, 1000);
    },

  },

  computed: {
    contadorColor(){
      if(this.colorContador == 1){
        let colorNuevo = "success"
        return colorNuevo
      }else{
        let colorNuevo = "warning"
        return colorNuevo
      }
    }
  },

  created() {
    this.iniciarContador();
  },
};
</script>
