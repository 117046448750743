import { db, auth } from "@/firebase";
import { calculaCountDown } from "@/utiles/calculaCountDown.js";
import { calculaMinutosPrevistosAhora } from "@/utiles/calculaMinutosPrevistosAhora.js";

async function maxMinGap(aid,maxGap,minGap,minutosTotalesHechos,finicio,minutosPrevistosPorDia,minutosTotalesAduug,diasTotales) {
     let ahora = Date.now();

     let mpa = calculaMinutosPrevistosAhora(finicio,minutosTotalesAduug,diasTotales)

      let gapDias =
        (minutosTotalesHechos - mpa) /
         minutosPrevistosPorDia;

      

      let gapMilisegundos = gapDias * 24 * 60 * 60 * 1000;

      var gapDate = new Date();
      

      gapDate = gapDate.setMilliseconds(gapMilisegundos);

       let deadline = gapDate - ahora;

       deadline = deadline - 1000;
       var Evaluargap = calculaCountDown(deadline);

        let cambio
        let mensajeEnviado

       if(gapDias>maxGap){

         cambio = {
            maxGap: gapDias,
            maxGapTxt: Evaluargap 
          };

          await db
           .collection("aduugs")
           .doc(aid)
           .update(cambio);
  
     }

     if( gapDias<minGap-0.5){

         cambio = {
            minGap: gapDias,
            minGapTxt: Evaluargap 
          };
          let ahora = new Date().getTime();
          mensajeEnviado = {
            texto: "New -" + Evaluargap,
            fechaEnvio: new Date(),
            fechaEnvioChart: ahora,
            uid: auth.currentUser.uid,
            user: "Aduug",
            isTarea: true,
            //tid: tid,
            minutos: 0,
            gap: gapDias
          };
          
          await db
           .collection("aduugs")
           .doc(aid)
           .update(cambio);
           
           await db
           .collection("aduugs")
           .doc(aid)
           .collection("chat")
           .add(mensajeEnviado);
           
          }
    //   let date = new Date(gapDate);

    //   this.fechaDeadLine = date.toString().substring(0, 21);

    //   let menosDeUnDia = ahora + 86400000;

    //   if (gapDate <= ahora) {
    //     this.colorContador = "error";
    //   } else if (gapDate <= menosDeUnDia) {
    //     this.colorContador = "warning";
    //   } else {
    //     this.colorContador = "success";
    //   }

    //   var aFecha1 = this.finicio.split("-");
    //   var aFecha2 = this.ffin.split("-");
    //   var fFecha1 = Date.UTC(aFecha1[0], aFecha1[1] - 1, aFecha1[2]);
    //   var fFecha2 = Date.UTC(aFecha2[0], aFecha2[1] - 1, aFecha2[2]);

    //   if (fFecha1 > ahora) {
    //     this.gap = "Not Started";
    //     this.colorContador=""
    //     this.fechaDeadLine = this.finicio
    //   }else if(!this.minutosPrevistosAhora){
    //     this.gap = "00d:00h:00m:00s";
    //     this.colorContador=""
    //   }else if (ahora > fFecha2 + 86400000) {
    //     this.gap = "Finished";
    //     this.colorContador=""
    //     this.fechaDeadLine = this.ffin
    //   } else {
        
            
        //   }
        // }
    

}
export { maxMinGap };
