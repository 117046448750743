import { db } from "@/firebase";

async function calculaMinutosTotalesHechos(aid) {
  let docs = await db
    .collection("aduugs")
    .doc(aid)
    .collection('tareas')
    .get();

    let tareasAduug = [];

        docs.forEach(doc => {
          tareasAduug.push(doc.data());
        });

     let minutosHechosTareasAduug = []  
        
     tareasAduug.forEach(tarea=>{

          var total

          total = tarea.puntosHechos * tarea.minutosPunto

          minutosHechosTareasAduug.push(total)
        })

        let nuevosMinutosTotalesAduug = minutosHechosTareasAduug.reduce(function (acc, elem){
          return acc+ elem
        })

        let cambio = {
          minutosTotalesHechos :nuevosMinutosTotalesAduug
        }

  await db
    .collection("aduugs")
    .doc(aid)
    .update(cambio);

  
}

export { calculaMinutosTotalesHechos };
