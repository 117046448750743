<template>
  <v-layout wrap text-center align-start justify-center class="mt-5">
    <v-card v-if="usuario" max-width="250">
      <v-btn
        @click="editando = !editando"
        v-if="perfilPropio"
        color="secondary"
        class="elevation-1"
        fab
        small
        absolute
        top
        right
      >
        <v-fade-transition mode="out-in">
          <v-icon v-if="!editando" :key="1">edit</v-icon>
          <v-icon v-else :key="2">close</v-icon>
        </v-fade-transition>
      </v-btn>
      <v-card-text>
        <v-layout justify-center>
          <div class="ma-2">
            {{ usuario.userName }}
          </div>
        </v-layout>
        <v-img class="ma-2 fotoPerfil" :src="fotoPerfil">
          <v-layout fill-height justify-end>
            <v-btn
              v-if="editando"
              :to="{ name: 'edicionFotoPerfil' }"
              color="white"
              icon
              large
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </v-layout>
        </v-img>
        <v-layout justify-center>
          <v-btn
            @click="editarDescripcion"
            v-if="editando"
            color="secondary"
            text
            icon
            small
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <div class="ma-2 descripcion">
            <span v-if="usuario.descripcion">{{ usuario.descripcion }}</span>
            <span v-else>Add your description</span>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-container fluid>
      <v-card max-width="500" class="mx-auto">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Aduugs Paused</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="aduugs.length == 0">No Aduugs Paused</v-card-text>
        <v-list>
          <v-list-item v-for="aduug in aduugs" :key="aduug.aid">
            <v-list-item-content>
              {{ aduug.aduugTitle }}
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                align-end
                @click="
                  recuperarAduugArchivado(
                    aduug.aid,
                    aduug.finicio,
                    aduug.ffin,
                    aduug.actualizado
                  )
                "
                color="success"
              >
                <v-icon>restore</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>

    <!-- Editar Descripción -->
    <v-dialog v-model="editandoDescripcion" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            Add your description
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            label="Something about you"
            v-model="edicionDescripcion"
            counter="300"
            autofocus
            :error-messages="erroresDescripcion"
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <v-layout justify-start>
                <v-btn @click="editandoDescripcion = false">Cancel</v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout justify-end>
                <v-btn
                  @click="guardarDescripcion"
                  :depressed="$v.edicionDescripcion.$invalid"
                  :disabled="$v.edicionDescripcion.$invalid"
                  color="secondary"
                  >Save</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card max-width="500" class="mx-auto"> 
      <v-tabs background-color="primary" centered dark>
        <v-tab>
          Free
        </v-tab>
        <v-tab>
          Gold
        </v-tab>
        <v-tab>
          Pro
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Explore & Clone Aduugs</v-list-item-title
                    >
                    <v-list-item-title>Colaborative Aduugs</v-list-item-title>
                    <v-list-item-title>Publish Aduugs</v-list-item-title>
                    <v-list-item-title>Reverse Aduugs</v-list-item-title>
                    <v-list-item-title>Daily Minutes Counter</v-list-item-title>
                    <v-list-item-title>Avatar Image</v-list-item-title>
                    <v-list-item-title
                      >Log with latest Activity</v-list-item-title
                    >
                    <v-list-item-title>Up to 2 Aduugs</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Explore & Clone Aduugs</v-list-item-title
                    >
                    <v-list-item-title>Colaborative Aduugs</v-list-item-title>
                    <v-list-item-title>Publish Aduugs</v-list-item-title>
                    <v-list-item-title>Reverse Aduugs</v-list-item-title>
                    <v-list-item-title>Daily Minutes Counter</v-list-item-title>
                    <v-list-item-title>Avatar Image</v-list-item-title>
                    <v-list-item-title
                      >Log with latest Activity</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Unlimited Aduugs</v-list-item-title>
                    <v-list-item-title
                      >Aduug Max/Min Free Time Counter</v-list-item-title
                    >
                    <v-list-item-title
                      >Aduug Strength Indicator</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <h2>4,99€/Month</h2>
            <h2>Cancel any time</h2>
            </v-card-text>
            <v-layout text-center align-start justify-center>
              <v-card-actions justify-center align-center>
                <v-btn
                  :loading="loading"
                  v-if="!botonGold && !botonPro"
                  color="secondary"
                  @click="stripeCheckout('price_1IFGVHK7byOhYAL9XBTmeRsG')"
                  >Get Gold 15 Days Trial
                </v-btn>
                <v-btn
                  :loading="loading"
                  v-if="botonGold && !botonPro"
                  color="secondary"
                  @click="createPortalLink"
                  >Manage Subscription
                </v-btn>
              </v-card-actions>
            </v-layout>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Explore & Clone Aduugs</v-list-item-title
                    >
                    <v-list-item-title>Colaborative Aduugs</v-list-item-title>
                    <v-list-item-title>Publish Aduugs</v-list-item-title>
                    <v-list-item-title>Reverse Aduugs</v-list-item-title>
                    <v-list-item-title>Daily Minutes Counter</v-list-item-title>
                    <v-list-item-title>Avatar Image</v-list-item-title>
                    <v-list-item-title
                      >Log with latest Activity</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Unlimited Aduugs</v-list-item-title>
                    <v-list-item-title
                      >Aduug Max/Min Free Time Counter</v-list-item-title
                    >
                    <v-list-item-title
                      >Aduug Strength Indicator</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Archive & Pause Aduugs</v-list-item-title
                    >
                    <v-list-item-title
                      >Aduug Max/Min Free time statistical
                      graph</v-list-item-title
                    >

                    <v-list-item-title>Calendar Heatmap</v-list-item-title>
                    <v-list-item-title
                      >Ranking of Colaborative Aduugs</v-list-item-title
                    >
                    <v-list-item-title
                      >Amazing New Features Upcoming</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            <h2>9,99€/Month</h2>
            <h2>Cancel any time</h2>
            </v-card-text>
            <v-layout text-center align-start justify-center>
              <v-card-actions justify-center align-center>
                <v-btn
                  :loading="loading"
                  v-if="!botonPro"
                  color="secondary"
                  @click="stripeCheckout('price_1IFGWCK7byOhYAL9ML5i1kS5')"
                  >Get Pro 15 Days Trial
                </v-btn>
                <v-btn
                  :loading="loading"
                  v-if="botonPro"
                  color="secondary"
                  @click="createPortalLink"
                  >Manage Subscription
                </v-btn>
              </v-card-actions>
            </v-layout>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-layout>
</template>
<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapMutations, mapGetters } from "vuex";
import { maxLength } from "vuelidate/lib/validators";
import { db, auth, firebase } from "@/firebase";
import { calculaNuevaFechaDesArchivar } from "@/utiles/calculaNuevaFechaDesArchivar.js";

export default {
  data() {
    return {
      usuario: null,
      editando: false,
      editandoNickname: false,
      editandoDescripcion: false,
      nickName: "",
      edicionDescripcion: "",
      aduugs: [],
      tab: null,
      text: "Gold y Pro",
      loading: false,
    };
  },
  validations: {
    edicionDescripcion: {
      maxLength: maxLength(300),
    },
  },
  created() {
    this.consultarUsuario();
    this.consultarAduugsArchivados();
  },
  watch: {
    $route() {
      this.consultarUsuario();
    },
  },
  methods: {
    ...mapMutations([
      "mostrarError",
      "mostrarOcupado",
      "ocultarOcupado",
      "mostrarAdvertencia",
    ]),
    ...mapMutations("sesion", ["actualizarNickname", "actualizarDescripcion"]),

    async consultarUsuario() {
      let userNameParametro = this.$route.params.userName.toLowerCase();

      this.mostrarOcupado({
        titulo: "Checking data",
        mensaje: "Loading data",
      });

      try {
        let userNameDoc = await db
          .collection("userNames")
          .doc(userNameParametro)
          .get();

        if (userNameDoc.exists) {
          let userName = userNameDoc.data();

          let usuarioDoc = await db
            .collection("usuarios")
            .doc(userName.uid)
            .get();

          if (usuarioDoc.exists) {
            this.usuario = usuarioDoc.data();
          } else {
            this.$router.push({ name: "404" });
          }
        } else {
          this.$router.push({ name: "404" });
        }
      } catch (error) {
        this.$router.push({ name: "404" });
      } finally {
        this.ocultarOcupado();
      }
    },

    editarDescripcion() {
      this.edicionDescripcion = this.usuario.descripcion || "";
      this.editandoDescripcion = true;
    },

    async guardarDescripcion() {
      if (this.edicionDescripcion == this.usuario.descripcion) {
        this.editandoDescripcion = false;
        return;
      }

      this.mostrarOcupado({
        titulo: "Updating Information",
        mensaje: "We are saving the description...",
      });

      try {
        await db
          .collection("usuarios")
          .doc(this.usuario.uid)
          .update({ descripcion: this.edicionDescripcion });

        this.actualizarDescripcion(this.edicionDescripcion);

        this.usuario.descripcion = this.edicionDescripcion;

        this.editandoDescripcion = false;
        this.editando = false;
      } catch (error) {
        this.mostrarError("An error occurred updating your data");
      } finally {
        this.ocultarOcupado();
      }
    },

    async consultarAduugsArchivados() {
      try {
        let docs = await db
          .collection("aduugs")
          .where("uid", "==", auth.currentUser.uid)
          .where("archivado", "==", true)
          .get();
        docs.forEach((doc) => {
          this.aduugs.push(doc.data());
          calculaNuevaFechaDesArchivar(
            doc.data().finicio,
            doc.data().ffin,
            doc.data().actualizado
          );
        });
      } catch (error) {
        this.mostrarError(
          "An error occurred. Please try again later"
        );
      }
    },

    async recuperarAduugArchivado(aid, finicio, ffin, actualizado) {
      let batch = db.batch();

      let nuevasFechas = calculaNuevaFechaDesArchivar(
        finicio,
        ffin,
        actualizado
      );

      let cambio = {
        archivado: false,
        finicio: nuevasFechas.finicio,
        ffin: nuevasFechas.ffin,
      };

      batch.update(db.collection("aduugs").doc(aid), cambio);

      await batch.commit();
      this.$router.push({ name: "dashboard" });
    },

    async stripeCheckout(price) {
      this.loading = true;
      const docRef = await db
        .collection("customers")
        .doc(this.usuario.uid)
        .collection("checkout_sessions")
        .add({
          price: price,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe

          const stripe = Stripe(
            "pk_live_51H9RpmK7byOhYAL9jT5lfDd6jUur5ju26ybKt7KNI9QPJvt4qAMwxeBlvIliT8a5MnbSAnn3wgePxGb3tu4GkYBU00uxEqNuhq"
          );
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },

    async createPortalLink() {
      this.loading = true;
      const functionRef = firebase
        .app()
        .functions("europe-west3")
        .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
    },
  },
  computed: {
    ...mapGetters("sesion", ["fotoPerfil", "subscripcionActual"]),
    perfilPropio() {
      return this.usuario && this.usuario.uid == auth.currentUser.uid;
    },

    erroresDescripcion() {
      let errores = [];
      if (!this.$v.edicionDescripcion.$dirty) {
        return errores;
      }
      if (!this.$v.edicionDescripcion.maxLength) {
        errores.push("Maximum 300 characters");
      }

      return errores;
    },

    botonPro() {
      if (this.subscripcionActual == "Pro") {
        return true;
      } else {
        return false;
      }
    },
    botonGold() {
      if (this.subscripcionActual == "Gold") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.fotoPerfil {
  width: 200px;
  height: 200px;
}

.descripcion {
  text-align: justify;
}

.link {
  text-decoration: none;
  font-size: 1rem;
  color: #553f75;
}
</style>
