<template>
  <v-layout justify-center align-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-slide-y-transition mode="out-in">
        <v-card>
          <v-toolbar color="primary" dark card>
            <v-toolbar-title>
              Register
            </v-toolbar-title>
          </v-toolbar>
          <!-- <v-card-text class="subheading text-center">
            Entra con tu cuenta de Google
          </v-card-text>
          <v-card-text>
            <v-layout justify-center>
              <a @click="registrar('google.com')">
                <v-avatar tile>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="48px"
                    height="48px"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                </v-avatar>
              </a>
            </v-layout>
          </v-card-text> -->
          <!-- <v-divider></v-divider> -->
          <!-- <v-card-text class="subheading text-center">
            Regístrate con una cuenta de email y contraseña
          </v-card-text> -->
          <v-card-text>
            <v-text-field
              v-model="f1.email"
              label="Email"
              :error-messages="erroresEmail"
              @blur="$v.f1.email.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="f1.password"
              label="Password"
              type="password"
              :error-messages="erroresPassword"
              @blur="$v.f1.password.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="f1.repetirPassword"
              label="Repeat Password"
              type="password"
              :error-messages="erroresRepetirPassword"
              @blur="$v.f1.repetirPassword.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="f1.userName"
              label="Username"
              :error-messages="erroresUserName"
              @blur="$v.f1.userName.$touch()"
            ></v-text-field>

            <v-checkbox
              v-model="f1.checkboxLegal"
              label="Accept Legal Advice"
              color="success"
              value="success"
              hide-details
              :error-messages="erroresCheckboxLegal"
            >
              <template v-slot:label>
                <div>
                  Accept 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="http://aduug.com/legal"
                        @click.stop
                        v-on="on"
                      >
                        Legal Advice
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="f1.checkboxPrivacy"
              label="Accept Privacy & Cookie Policy"
              color="success"
              value="success"
              hide-details
              :error-messages="erroresCheckboxPrivacy"
            >
            <template v-slot:label>
                <div>
                  Accept 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="http://aduug.com/privacy"
                        @click.stop
                        v-on="on"
                      >
                        Privacy & Cookie Policy
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-card-text>

          <v-card-text>
            <v-layout justify-center>
              <v-btn
                :disabled="$v.f1.$invalid"
                @click="registrar('password')"
                color="secondary"
                >Register</v-btn
              >
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center>
              <v-btn :to="{ name: 'login' }" flat color="secondary">
                Have Account? Login!
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-slide-y-transition>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  alphaNum,
} from "vuelidate/lib/validators";

import { firebase, auth, db } from "@/firebase";
import { mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      metodo: "password",
      f1: {
        checkboxLegal: false,
        checkboxPrivacy: false,
        email: "",
        password: "",
        repetirPassword: "",
        userName: "",
      },
    };
  },
  validations: {
    f1: {
      checkboxLegal: {
        required,
      },
      checkboxPrivacy: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
      },
      repetirPassword: {
        sameAs: sameAs("password"),
      },
      userName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
        alphaNum,
      },
    },
  },
  created() {
    if (auth.currentUser && !this.$store.state.sesion.usuario) {
      this.metodo = auth.currentUser.providerData[0].providerId;
    }
  },
  methods: {
    ...mapMutations([
      "mostrarOcupado",
      "ocultarOcupado",
      "mostrarExito",
      "mostrarError",
      "mostrarAdvertencia",
    ]),
    ...mapMutations("sesion", ["actualizarUsuario"]),

    async registrar() {
      // if (this.$v.userName.$invalid) {
      //   return;
      // }

      if(this.f1.checkboxLegal == false || this.f1.checkboxPrivacy == false){
        this.mostrarAdvertencia("Please, accept Legal Advice & Data Policy");
        return
      }

      let userNameExistente = await db
        .collection("userNames")
        .doc(this.f1.userName.toLowerCase())
        .get();

      if (userNameExistente.exists) {
        this.mostrarAdvertencia("Username already exist");
        return;
      }

      switch (this.metodo) {
        case "password":
          this.registrarEmail();
          break;

        case "google.com":
          this.registrarGoogle();
          break;
      }
    },

    async registrarEmail() {
      try {
        this.mostrarOcupado({
          titulo: "Registering User",
          mensaje: "Saving Information",
        });

        let uid = null;

        if (auth.currentUser) {
          uid = auth.currentUser.uid;
        } else {
          let cred = await auth.createUserWithEmailAndPassword(
            this.f1.email,
            this.f1.password
          );
          uid = cred.user.uid;
        }

        await this.guardarUsuario(uid);

        await auth.currentUser.sendEmailVerification();

        this.$router.push({ name: "emailVerification",params: { checkMail: this.f1.email } });
      } catch (error) {
        switch (error.code) {
          case "auth/email-already-in-use":
            this.mostrarAdvertencia(
              "This email address has already been registered before"
            );
            break;

          default:
            this.mostrarError("An error occurred. Try again later");
            break;
        }
      } finally {
        this.ocultarOcupado();
      }
    },

    async registrarGoogle() {
      let provider = new firebase.auth.GoogleAuthProvider();

      provider.setCustomParameters({
        display: "popup",
      });

      auth.languageCode = "en_US";

      try {
        let uid = null;

        if (auth.currentUser) {
          uid = auth.currentUser.uid;
        } else {
          let cred = await auth.signInWithPopup(provider);
          uid = cred.user.uid;
        }

        await this.guardarUsuario(uid);
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.mostrarError("An error occurred. Try again later");
      }
    },

    async guardarUsuario(uid) {
      let usuario = {
        uid,
        userName: this.f1.userName,
        email: this.f1.email,
        fotoPerfil: this.fotoPerfil,
        token:uid
      };

      let userName = {
        userName: this.f1.userName,
        email: this.f1.email,
        uid,
      };

      let batch = db.batch();

      batch.set(db.collection("usuarios").doc(usuario.uid), usuario);
      batch.set(
        db.collection("userNames").doc(this.f1.userName.toLowerCase()),
        userName
      );

      await batch.commit();

      this.actualizarUsuario(usuario);
      this.mostrarExito(this.saludo);
    },
  },
  computed: {
    ...mapGetters("sesion", ["saludo", "fotoPerfil"]),
    erroresEmail() {
      let errores = [];
      if (!this.$v.f1.email.$dirty) {
        return errores;
      }
      if (!this.$v.f1.email.required) {
        errores.push("Enter a email");
      }
      if (!this.$v.f1.email.email) {
        errores.push("Enter a valid email");
      }
      return errores;
    },
    erroresPassword() {
      let errores = [];
      if (!this.$v.f1.password.$dirty) {
        return errores;
      }
      if (!this.$v.f1.password.required) {
        errores.push("Enter password");
      }
      if (!this.$v.f1.password.minLength) {
        errores.push("Min 6 characters");
      }
      if (!this.$v.f1.password.maxLength) {
        errores.push("Max 20 characters");
      }

      return errores;
    },
    erroresRepetirPassword() {
      let errores = [];
      if (!this.$v.f1.repetirPassword.$dirty) {
        return errores;
      }
      if (!this.$v.f1.repetirPassword.sameAs) {
        errores.push("Passwords do not match");
      }
      return errores;
    },
    erroresUserName() {
      let errores = [];
      if (!this.$v.f1.userName.$dirty) {
        return errores;
      }
      if (!this.$v.f1.userName.required) {
        errores.push("Enter a username");
      }
      if (!this.$v.f1.userName.minLength) {
        errores.push("Min 3 characters");
      }
      if (!this.$v.f1.userName.maxLength) {
        errores.push("Max 20 characters");
      }
      if (!this.$v.f1.userName.alphaNum) {
        errores.push("Enter only letters and numbers, no spaces");
      }

      return errores;
    },
    erroresCheckboxLegal() {
      let errores = [];
      if (!this.$v.f1.checkboxLegal.$dirty) {
        return errores;
      }

      return errores;
    },

    erroresCheckboxPrivacy() {
      let errores = [];
      if (!this.$v.f1.checkboxPrivacy.$dirty) {
        return errores;
      }

      return errores;
    },
  },
};
</script>
