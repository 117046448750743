//import { db } from "@/firebase";


function calculaMinutosPrevistosAhora(finicio,minutosTotalesAduug,diasTotales) {
  
 //let doc = await db.collection("aduugs").doc(aid).get();

  //let aduug = doc.data()
  //let ahora = new Date().getTime();
  
   var anoHoy = new Date().getFullYear()
   var mesHoy = new Date().getMonth()
   var diaHoy = new Date().getDate()
   var horasHoy = new Date().getHours()
   var minutosHoy = new Date().getMinutes()  
  
   var fechaInicio = finicio.split("-")
  
  var fechaHoy = new Date(anoHoy,mesHoy,diaHoy,horasHoy,minutosHoy)
  var inicioFecha = new Date(fechaInicio[0],fechaInicio[1]-1,fechaInicio[2])
  
  var dif = fechaHoy - inicioFecha
  
  var dias = dif / (1000 * 60 * 60 * 24);
  
  var diasPasados = dias
  
  let minutosPorDia = minutosTotalesAduug / diasTotales

  let resultadoMinutosPrevistosAhora =  (minutosPorDia * diasPasados)
  
  // if(aduug.aMPA + 300000 > ahora){
  //   return
  // }else{
    
  //   let cambio = {
  //     minutosPrevistosAhora: resultadoMinutosPrevistosAhora,
  //     aMPA: ahora 
  //   };
    
  //   await db
  //   .collection("aduugs")
  //   .doc(aid)
  //   .update(cambio);
  // }
    
  return resultadoMinutosPrevistosAhora;
}

export { calculaMinutosPrevistosAhora };
